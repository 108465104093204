import React, { useCallback, useState, useRef } from "react";
import DeckGL from "@deck.gl/react";
import { GeoJsonLayer } from "@deck.gl/layers";
import buffer from "@turf/buffer";
import bbox from "@turf/bbox";
import { StaticMap } from "react-map-gl";
import { EuiAspectRatio } from "@elastic/eui";
import { IconLayer } from "@deck.gl/layers";
import icons from "./sprite.png";
import Hover from "components/MapHover";
const ICON_MAPPING = {
  marker: { x: 196, y: 56, width: 16, height: 28, mask: true }
};
const numberFormat = new Intl.NumberFormat("de-DE", {
  style: "unit",
  unit: "meter",
  maximumFractionDigits: 0
});
const MapContainer = ({ data, marker }) => {
  const [viewState, setViewState] = useState({
    longitude: 10.4515,
    latitude: 51.1657,
    zoom: 17,
    pitch: 0,
    bearing: 0
  });
  const [hover, setHover] = useState(false);
  const mapRef = useRef();
  const handleViewStateChange = e => setViewState(e.viewState);
  const setIntialView = () => {
    let box;
    if (data.features.length === 0) {
      box = bbox(
        buffer(
          {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",

                geometry: marker[0]
              }
            ]
          },
          1
        )
      );
    } else {
      box = bbox(buffer(data, 1));
    }
    const {
      longitude,
      latitude,
      zoom,
      pitch,
      bearing
    } = mapRef.current.deck.viewManager._viewports[0].fitBounds([
      [box[0], box[1]],
      [box[2], box[3]]
    ]);
    setViewState({ longitude, latitude, zoom, pitch, bearing });
  };

  function handleHover(data) {
    if (data?.picked) {
      const [x, y] =
        data.object.geometry.type === "Point"
          ? data.layer.context.viewport.project(
              data.object.geometry.coordinates
            )
          : data.coordinate;

      setHover({ x, y, object: data.object });
    } else setHover(false);
  }
  const source = new GeoJsonLayer({
    autoHighlight: true,
    data: data,
    id: "source",
    stroked: true,
    filled: true,
    extruded: false,
    lineWidthScale: 1,
    lineWidthMinPixels: 2,
    getLineColor: e => e.properties.color,
    pickable: true,
    getRadius: 4,
    getFillColor: e => e.properties.color,
    pointRadiusMinPixels: 4
  });

  // const icon = new IconLayer({
  //   id: "entryIcon",
  //   data: marker.filter(e => e.type === "Point"),

  //   pickable: false,

  //   iconAtlas: icons,
  //   iconMapping: ICON_MAPPING,
  //   getIcon: d => "marker",
  //   sizeMinPixels: 30,
  //   getPosition: d => d.coordinates,
  //   getColor: d => [14, 90, 138]
  // });

  return (
    <EuiAspectRatio width={16} height={9}>
      <DeckGL
        onHover={handleHover}
        onLoad={setIntialView}
        ref={mapRef}
        id="showCanvas"
        layers={[source]}
        controller={true}
        viewState={viewState}
        onViewStateChange={handleViewStateChange}
      >
        <StaticMap />
        {hover && (
          <Hover
            x={hover.x}
            y={hover.y}
            data={[
              ...hover.object.properties.eintrag,
              `Abstand: ${numberFormat.format(
                hover.object.properties.abstand
              )}`,
              `${hover.object.properties.kollektion.join(", ")}`
            ]}
          />
        )}
      </DeckGL>
    </EuiAspectRatio>
  );
};

export default MapContainer;

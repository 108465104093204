import React from "react";
import FormModal from "components/FormModal";

import { EuiFormRow, EuiFieldText, EuiFilePicker } from "@elastic/eui";
const Form = ({ values, handleChange, ...props }) => {
  return (
    <FormModal title="Dateien" {...props}>
      <EuiFormRow label="Name">
        <EuiFieldText
          name="name"
          value={values.name}
          onChange={e => handleChange("name", e.target.value)}
        />
      </EuiFormRow>

      <EuiFormRow label="Datei">
        <EuiFilePicker
          multiple
          initialPromptText="Select or drag and drop multiple files"
          onChange={files => {
            handleChange("file", files);
          }}
          display={"large"}
        />
      </EuiFormRow>
    </FormModal>
  );
};

export default Form;

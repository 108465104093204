import React from "react";
import {
  EuiNavDrawer,
  EuiNavDrawerGroup,
  EuiHorizontalRule
} from "@elastic/eui";
import { useSelector } from "react-redux";
import { matchPath, useHistory, useLocation } from "react-router-dom";
const NavDrawer = React.forwardRef((props, ref) => {
  const history = useHistory();
  const location = useLocation();
  const scope = useSelector(state => state.auth.data.scope);
  return (
    <EuiNavDrawer ref={ref}>
      <EuiNavDrawerGroup
        listItems={[
          {
            label: "Einträge",
            iconType: "document",
            path: "/entries"
          },
          {
            label: "Karte",
            iconType: "visMapRegion",
            path: "/entriesMap"
          },
          {
            label: "Kollektionen",
            iconType: "submodule",
            path: "/collections"
          },
          {
            label: "Abstandsregeln",
            iconType: "training",
            path: "/distanceRules"
          },
          {
            label: "Datenformate",
            iconType: "database",
            path: "/PropertyTemplates"
          },
          {
            label: "Filter",
            iconType: "filter",
            path: "/filters"
          },
          {
            label: "Benutzer",
            iconType: "user",
            path: "/Users",
            hidden: !scope.includes("PROKSIMO:ADMIN")
          },
          {
            label: "Dateien",
            iconType: "save",
            path: "/Files",
            hidden: !scope.includes("PROKSIMO:ADMIN")
          },

          {
            label: "Import",
            iconType: "importAction",
            path: "/import",
            hidden: !scope.includes("PROKSIMO:ADMIN")
          }
        ]
          .filter(e => e.hidden !== true)
          .map(e => decorate(e, history, location.pathname))}
      />

      <EuiHorizontalRule margin="none" />
    </EuiNavDrawer>
  );
});

export default NavDrawer;

const decorate = ({ path, ...data }, history, location) => {
  return {
    ...data,
    onClick: () => history.push(path),
    isActive: matchPath(location, path) !== null
  };
};

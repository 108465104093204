import { createSlice } from "@reduxjs/toolkit";
import ducksHelper from "helpers/ducks";

const name = "distance_rules";
const {
  // names: { REQUEST, UPDATE, SUCCESS, FAILURE, CREATE, DELETE },
  // schema,
  defaultState,
  actions: { list, create, update, destroy }
} = ducksHelper(name);

const slice = createSlice({
  name: name,
  initialState: defaultState,
  reducers: {
    delete: (state, action) => {
      const ids = Object.keys(action.payload.entities[name]);
      ids.forEach(e => {
        delete state.items[e];
      });
      state.updated_at = new Date();
      state.isLoading = false;
    },
    success: (state, action) => ({
      isLoading: false,
      update_at: new Date(),
      items: { ...state.items, ...action.payload.entities[name] }
    })
  }
});

export default slice.reducer;
export { update, list, create, destroy };

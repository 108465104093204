import { createSelector } from "@reduxjs/toolkit";
import { getFormat, formatEntry } from "helpers/format";
const entriesSelector = state => state.entries.items;
const neighboursSelector = state => state.neighbours.items;
const collisionsSelector = state => state.collisions.items;
const propertyTemplatesSelector = state => state.property_templates.items;
const propertiesSelector = state => state.properties.items;
const collectionsSelector = state => state.collections.items;

const fullEntries = createSelector(
  entriesSelector,
  neighboursSelector,
  collisionsSelector,
  collectionsSelector,
  (entries, neighbours, collisions, collections) => {
    return Object.keys(entries).reduce((r, e) => {
      const entry = entries[e];
      const format = getFormat(entry, collections);
      const entryFormat =
        format === undefined
          ? ["<Keine Formatierung festgelegt>"]
          : formatEntry(entry, format);
      const color = collections[entry.collection_ids[0]]?.color ?? [
        255,
        255,
        255,
        100
      ];
      r[e] = {
        ...entry,
        entryFormat,
        collectionNames: entry.collection_ids.map(e => collections[e].name),
        color: [...color.slice(0, 3), color[3] * 2.55],
        neighbours: neighbours[e],
        collisions: collisions[e]
      };
      return r;
    }, {});
  }
);
export const entriesMap = createSelector(entriesSelector, entries =>
  Object.values(entries)
);

export const fullEntriesMap = createSelector(fullEntries, entries =>
  Object.values(entries)
);

export const extraFullEntries = createSelector(
  fullEntries,
  propertyTemplatesSelector,
  propertiesSelector,
  (entries, propertyTemplates, properties) => {
    return Object.keys(entries).reduce((r, e) => {
      const entry = entries[e];

      const elementProperties = entry.properties.map(e => properties[e]);
      const elementPropertyTemplates = elementProperties
        .map(e => e.property_template_id)
        .filter((e, i, a) => a.indexOf(e) === i);

      r[e] = {
        ...entry,
        properties: elementProperties,
        propertyTemplates: elementPropertyTemplates.map(
          e => propertyTemplates[e]
        )
      };
      return r;
    }, {});
  }
);

export default fullEntries;

import React, { useState } from "react";
import Content from "components/Content";
import { EuiTabs, EuiTab, EuiButton, EuiEmptyPrompt } from "@elastic/eui";
import { useAddQueryNavigation } from "helpers/hooks/url";
import PropertyPanel from "./PropertyPanel";

const PropertyTabs = ({ entry, templates }) => {
  const [selectedDataTab, setSelectedDataTab] = useState(templates[0]?.id);
  const tab =
    templates.find(e => e.id === selectedDataTab)?.id ?? templates[0]?.id;
  const addQuery = useAddQueryNavigation();
  const tabs = templates
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((e, i) => ({
      isSelected: tab === e.id,
      id: e.id,
      name: e.name,
      template: e
    }));
  const template = tabs.find(e => e.id === tab)?.template;
  return (
    <Content
      title="Daten"
      actions={
        <EuiButton
          size="s"
          onClick={() => addQuery("createProperty", entry.id)}
          iconType="listAdd"
        >
          Feld hinzufügen
        </EuiButton>
      }
      paddingSize="m"
    >
      <Tabs tabs={tabs} onChange={setSelectedDataTab} />
      {template ? (
        <PropertyPanel
          properties={entry.properties.filter(
            e => e.property_template_id === tab
          )}
          template={template}
        />
      ) : (
        <Empty />
      )}
    </Content>
  );
};

export default PropertyTabs;

const Tabs = ({ tabs, onChange }) => (
  <EuiTabs tabs={tabs}>
    {tabs.map(e => (
      <EuiTab
        key={e.id}
        isSelected={e.isSelected}
        onClick={() => onChange(e.id)}
      >
        {e.name}
      </EuiTab>
    ))}
  </EuiTabs>
);

const Empty = () => (
  <EuiEmptyPrompt
    iconType="editorStrike"
    title={<h2>Keine Daten vorhanden</h2>}
    // body={
    //   <>
    //     <p>
    //       Navigators use massive amounts of spice to gain a limited form of
    //       prescience. This allows them to safely navigate interstellar space,
    //       enabling trade and travel throughout the galaxy.
    //     </p>
    //     <p>You&rsquo;ll need spice to rule Arrakis, young Atreides.</p>
    //   </>
    // }
    // actions={
    //   <EuiButton color="primary" fill>
    //     Harvest spice
    //   </EuiButton>
    // }
  />
);

import { useState, useCallback, useEffect } from "react";
import useDebouncedCallback from "./useDebouncedCallBack";
import { useSelector } from "react-redux";
const host = process.env.REACT_APP_API_ENDPOINT;

export async function geocode(query, token) {
  return await fetch(`${host}/geocode?query=${query}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
    // .then(repsonse => {
    //   if (!repsonse.ok) {
    //     throw Error(repsonse.statusText);
    //   }
    //   return repsonse;
    // })
    .then(e => e.json());
}

function useGeocode(state) {
  const [query, setQuery] = useState(state);
  const [results, setResults] = useState([]);
  const token = useSelector(state => state.auth.token);
  const [debounceLookUp] = useDebouncedCallback(
    () => geocode(query, token).then(e => setResults(e)),
    1000
  );
  useEffect(() => {
    debounceLookUp();
  }, [query]);
  return [results, setQuery];
}
export default useGeocode;

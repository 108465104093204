import React from "react";
import { EuiCodeBlock } from "@elastic/eui";

const JSONBlock = ({ children, data }) => {
  const jsondata = data ?? children ?? { error: "nodata" };
  return (
    <EuiCodeBlock language="JSON">
      {JSON.stringify(jsondata, null, 2)}
    </EuiCodeBlock>
  );
};

export default JSONBlock;

import React from "react";
import { EuiSuggest } from "@elastic/eui";
import useGeocode from "helpers/hooks/useGeocode";
const GeoCodeField = ({ onChange }) => {
  const [results, setQuery] = useGeocode();
  return (
    <EuiSuggest
      onInputChange={e => {
        setQuery(e.value);
      }}
      onItemClick={e => onChange(e)}
      suggestions={results.map(e => ({
        type: { iconType: "globe", color: "tint3" },
        label: `${e.Straße} ${e.Hausnummer}, ${e.Postleitzahl} ${e.Ort}`,
        data: e
      }))}
    ></EuiSuggest>
  );
};

export default GeoCodeField;

import { useCallback } from "react";
import { useSelector } from "react-redux";

const useNeighbours = selector => {
  const entries = useSelector(selector);
  const allDistanceRules = useSelector(state => state.distance_rules.items);
  const returnFunction = useCallback(
    entry => {
      const neighbours = entry.neighbours.results.map(n => {
        return {
          ...entries[n.id],
          distance: n.distance,
          distanceRules: Object.entries(entry.collisions.distancerules)
            .filter(([k, v]) => v.results.map(e => e.id).includes(n.id)) //TODO this is shit
            .map(([k, v]) => parseInt(k, 10))
        };
      });
      return neighbours.map(
        ({
          id,
          distance,
          distanceRules,
          entryFormat,
          collectionNames,
          color,
          geojson
        }) => ({
          id,
          abstand: distance,
          eintrag: entryFormat,
          kollision: distanceRules.length > 0,
          kollektion: collectionNames,
          color,
          geojson,
          regel: distanceRules.map(e => allDistanceRules[e].name)
        })
      );
    },
    [entries, allDistanceRules]
  );
  return returnFunction;
};

export default useNeighbours;

import { createSlice } from "@reduxjs/toolkit";
const slice = createSlice({
  name: "ui/entriesMap",
  initialState: {
    picked: false,
    radius: 500,
    viewState: {
      longitude: 10.4515,
      latitude: 51.1657,
      zoom: 6,
      pitch: 0,
      bearing: 0
    }
  },
  reducers: {
    setRadius: (state, { payload }) => {
      return { ...state, radius: payload };
    },
    setPicked: (state, { payload }) => {
      return { ...state, picked: payload };
    },
    setViewState: (state, { payload }) => {
      return { ...state, viewState: payload };
    }
  }
});

export default slice.reducer;
const { setViewState, setPicked, setRadius } = slice.actions;
export { setViewState, setPicked, setRadius };

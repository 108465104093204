import React from "react";
import { useQuery } from "helpers/hooks/url";

import EditUser from "features/Modals/Users/EditUser";
import CreateUser from "features/Modals/Users/CreateUser";
import DeleteUser from "features/Modals/Users/DeleteUser";

import CreateFile from "features/Modals/Files/CreateFile";
import DeleteFile from "features/Modals/Files/DeleteFile";
import EditFile from "features/Modals/Files/EditFile";

import EditPropertyTemplate from "features/Modals/PropertyTemplates/EditPropertyTemplate";
import CreatePropertyTemplate from "features/Modals/PropertyTemplates/CreatePropertyTemplate";
import DeletePropertyTemplate from "features/Modals/PropertyTemplates/DeletePropertyTemplate";

import EditCollection from "features/Modals/Collections/EditCollection";
import CreateCollection from "features/Modals/Collections/CreateCollection";
import DeleteCollection from "features/Modals/Collections/DeleteCollection";

import EditFilter from "features/Modals/Filters/EditFilter";
import CreateFilter from "features/Modals/Filters/CreateFilter";
import DeleteFilter from "features/Modals/Filters/DeleteFilter";

import EditEntry from "features/Modals/Entries/EditEntry";
// import CreateEntry from "features/Modals/Entries/CreateEntry";
import DeleteEntry from "features/Modals/Entries/DeleteEntry";

import EditProperty from "features/Modals/Properties/EditProperty";
import CreateProperty from "features/Modals/Properties/CreateProperty";
import DeleteProperty from "features/Modals/Properties/DeleteProperty";

import EditDistanceRule from "features/Modals/DistanceRules/EditDistanceRule";
import CreateDistanceRule from "features/Modals/DistanceRules/CreateDistanceRule";
import DeleteDistanceRule from "features/Modals/DistanceRules/DeleteDistanceRule";

const Modals = () => {
  const query = useQuery();
  return (
    <>
      {query.deleteUser && <DeleteUser id={query.deleteUser} />}
      {query.editUser && <EditUser id={query.editUser} />}
      {query.createUser && <CreateUser />}

      {query.createFile && <CreateFile />}
      {query.deleteFile && <DeleteFile id={query.deleteFile} />}
      {query.editFile && <EditFile id={query.editFile} />}

      {query.editPropertyTemplate && (
        <EditPropertyTemplate id={query.editPropertyTemplate} />
      )}
      {query.createPropertyTemplate && <CreatePropertyTemplate />}
      {query.deletePropertyTemplate && (
        <DeletePropertyTemplate id={query.deletePropertyTemplate} />
      )}

      {query.createCollection && <CreateCollection />}
      {query.editCollection && <EditCollection id={query.editCollection} />}
      {query.deleteCollection && (
        <DeleteCollection id={query.deleteCollection} />
      )}

      {query.deleteFilter && <DeleteFilter id={query.deleteFilter} />}
      {query.editFilter && <EditFilter id={query.editFilter} />}
      {query.createFilter && <CreateFilter />}

      {query.deleteEntry && <DeleteEntry id={query.deleteEntry} />}
      {query.editEntry && <EditEntry id={query.editEntry} />}
      {/* {query.createEntry && <CreateEntry />} */}

      {query.editProperty && <EditProperty id={query.editProperty} />}
      {query.deleteProperty && <DeleteProperty id={query.deleteProperty} />}
      {query.createProperty && (
        <CreateProperty entry_id={query.createProperty} />
      )}

      {query.createDistanceRule && <CreateDistanceRule />}
      {query.deleteDistanceRule && (
        <DeleteDistanceRule id={query.deleteDistanceRule} />
      )}
      {query.editDistanceRule && (
        <EditDistanceRule id={query.editDistanceRule} />
      )}
    </>
  );
};

export default Modals;

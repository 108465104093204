import React from "react";
import {
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
  EuiInMemoryTable
} from "@elastic/eui";
import { useAddQueryNavigation } from "helpers/hooks/url";
import "./style.css";
const PropertyPanel = ({ properties, template }) => {
  return <Table properties={properties} template={template}></Table>;
};
export default PropertyPanel;

const Single = ({ property, template }) => (
  <EuiDescriptionList>
    {template.fields.map(e => (
      <>
        <EuiDescriptionListTitle>{e.label}</EuiDescriptionListTitle>
        <EuiDescriptionListDescription>
          {property.data[e.label] || "#NV"}
        </EuiDescriptionListDescription>
      </>
    ))}
  </EuiDescriptionList>
);

const Table = ({ properties, template }) => {
  const addQuery = useAddQueryNavigation();

  const actions = [
    {
      name: "Löschen",
      description: "Diesen Eintrag löschen",
      icon: "trash",
      color: "danger",
      type: "icon",
      onClick: e => {
        addQuery("deleteProperty", e.id);
      }
    },
    {
      name: "Edit",
      isPrimary: true,
      description: "Diesen Eintrag bearbeiten",
      icon: "pencil",
      type: "icon",
      isPrimary: true,

      onClick: e => {
        addQuery("editProperty", e.id);
      }
    }
  ];
  const columns = [
    ...template.fields.map(e => ({ field: `data.${e.label}`, name: e.label })),
    {
      name: "Aktionen",
      actions,
      width: "100px"
    }
  ];

  return (
    <EuiInMemoryTable
      className={properties.length === 1 ? "singleTable" : ""}
      items={properties}
      columns={columns}
      pagination={properties.length > 10}
      allowNeutralSort
    />
  );
};

import React from "react";
import ConfirmModal from "components/ConfirmModal";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
import { connect } from "react-redux";
import { destroy } from "ducks/users";
const DeleteUser = ({ id, destroy }) => {
  const closeDestroyModal = useRemoveQueryNavigation("deleteUser");

  return (
    <ConfirmModal
      title="Benutzer wirklich löschen?"
      confirm={() => {
        destroy(id);
        closeDestroyModal();
      }}
      close={closeDestroyModal}
    />
  );
};

export default connect(null, { destroy })(DeleteUser);

import React, { useState } from "react";
import { connect } from "react-redux";
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiText,
  EuiSpacer,
  EuiPopover
} from "@elastic/eui";
import { logout } from "ducks/auth";
import { useAddQueryNavigation } from "helpers/hooks/url";

const UserHeader = ({ user, logout }) => {
  const [isOpen, setOpen] = useState(false);
  const addQuery = useAddQueryNavigation();

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls="headerUserMenu"
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={() => setOpen(true)}
    >
      <EuiAvatar name={user.username} size="s" />

      {/* <EuiNotificationBadge className="euiHeaderNotification">
          3
        </EuiNotificationBadge> */}
    </EuiHeaderSectionItemButton>
  );

  return (
    <EuiPopover
      id="headerUserMenu"
      ownFocus
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={() => setOpen(false)}
      panelPaddingSize="none"
    >
      <div style={{ width: 320 }}>
        <EuiFlexGroup
          gutterSize="m"
          className="euiHeaderProfile"
          responsive={false}
        >
          <EuiFlexItem grow={false}>
            <EuiAvatar name={user.username} size="xl" />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>{user.username}</p>
            </EuiText>

            <EuiSpacer size="m" />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent="spaceBetween">
                  <EuiFlexItem grow={false}>
                    <EuiLink
                      disabled={true}
                      onClick={() => addQuery("editUser", user.id)}
                    >
                      Bearbeiten
                    </EuiLink>
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={logout}>Abmelden</EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>

        {/* <EuiHeaderAlert
            title="Here&rsquo;s a notification title"
            text="I am the hat judge. Show me a hat and I will tell you if it&rsquo;s a good hat or bad hat."
            date="Nov. 14, 02:14PM."
          />

          <EuiHeaderAlert
            title="Here&rsquo;s a notification title that is extremely long and will wrap"
            text="I am the hat judge. Show me a hat and I will tell you if it&rsquo;s a good hat or bad hat."
            action={<EuiLink href="#">Download your thing here</EuiLink>}
            date="Nov. 14, 02:14PM."
          />

          <EuiHeaderAlert
            title="Here&rsquo;s a notification title"
            text="I am the hat judge. Show me a hat and I will tell you if it&rsquo;s a good hat or bad hat."
            action={<EuiLink href="#">Download your thing here</EuiLink>}
            date="Nov. 14, 02:14PM."
          /> */}
      </div>
    </EuiPopover>
  );
};

const mapState = state => ({ user: state.auth.data });
export default connect(mapState, { logout })(UserHeader);

import React from "react";
import { useSelector } from "react-redux";
import jsonata from "jsonata";
import "./style.css";
import {
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiIcon,
  EuiSpacer,
  EuiComboBox,
  EuiFacetButton,
  EuiFacetGroup,
  EuiTreeView,
  EuiToken,
  EuiDualRange,
  EuiCheckbox
} from "@elastic/eui";
import { fullEntriesMap } from "selectors/entries";
const Boolean = ({ value, handleChange, fieldId, label }) => {
  return (
    <>
      <EuiCheckbox
        id={fieldId}
        label={label}
        name={fieldId}
        compressed
        checked={value ?? true}
        indeterminate={value === undefined}
        onChange={e =>
          handleChange(
            fieldId,
            value === true ? false : value === false ? undefined : true
          )
        }
      />
      <EuiSpacer size="m" />
    </>
  );
};
const TextInput = ({ value, handleChange, fieldId }) => {
  return (
    <EuiFieldText
      name={fieldId}
      compressed
      value={value ?? ""}
      onChange={e => handleChange(fieldId, e.target.value)}
    />
  );
};
const MultiSelect = ({ value, handleChange, fieldId, path }) => {
  const entries = useSelector(fullEntriesMap);

  if (path === undefined) return false;
  const expression = jsonata(`$distinct(${path})`);
  const options = (expression.evaluate(entries) ?? [])
    .filter(e => e !== "")
    .sort((a, b) => a.localeCompare(b));

  return (
    <EuiComboBox
      isClearable
      options={options.map(e => ({
        label: e
      }))}
      compressed
      selectedOptions={(value ?? []).map(e => ({ label: e }))}
      onChange={e =>
        handleChange(
          fieldId,
          e.map(x => x.label)
        )
      }
    />
  );
};
const Facet = ({ value = [], handleChange, fieldId, path }) => {
  const entries = useSelector(fullEntriesMap);

  if (path === undefined) return false;
  const expression = jsonata(`${path}`);
  const options = (expression.evaluate(entries) ?? []).reduce((r, e) => {
    r[e] = (r[e] ?? 0) + 1;
    return r;
  }, {});
  return (
    <EuiFacetGroup>
      {Object.keys(options)
        .sort((a, b) => a.localeCompare(b, "de-de", { numeric: true }))
        .map(e => (
          <EuiFacetButton
            key={e}
            isSelected={value.includes(e)}
            onClick={() =>
              handleChange(
                fieldId,
                value.includes(e) ? value.filter(x => e !== x) : [e, ...value]
              )
            }
            quantity={options[e]}
          >
            {e}
          </EuiFacetButton>
        ))}
    </EuiFacetGroup>
  );
};

const Range = ({ value, handleChange, fieldId, path }) => {
  const entries = useSelector(fullEntriesMap);

  if (path === undefined) return false;
  const expression = jsonata(`${path}`);
  const pathResult = expression.evaluate(entries);

  let min;
  let max;
  if (pathResult === undefined || pathResult.length === 0) {
    min = 0;
    max = 100;
  } else {
    max = Math.max(...pathResult);
    min = Math.min(...pathResult);
  }
  return (
    <>
      <EuiDualRange
        min={min}
        max={max}
        id={fieldId}
        value={[value?.[0] ?? min, value?.[1] ?? max]}
        onChange={value => handleChange(fieldId, value)}
        showInput
        minInputProps={{ "aria-label": "Min value" }}
        maxInputProps={{ "aria-label": "Max value" }}
      />
      <EuiSpacer size="m" />
    </>
  );
};

function buildTree(items, level = 0, handleChange, value) {
  if (items.length === 0) return [];
  return items
    .filter(e => e.path.length === level + 1)
    .map(e => {
      const children = buildTree(
        items.filter(c => c.path.includes(e.id) && c.path.length > level + 1),
        level + 1,
        handleChange,
        value
      );
      return {
        icon: <EuiIcon type="arrowRight" />,
        iconWhenExpanded: <EuiIcon type="arrowDown" />,
        label: e.name,
        id: `${e.id}`,
        children: [
          {
            icon: <EuiToken size="xs" iconType="tokenObject" />,
            label: "Einträge",
            id: `${e.id}entries`,
            callback: () => handleChange(e.id),
            className: value.includes(e.id) ? "activeTreeItem" : null
          },
          ...children
        ]
      };
    });
}

const Tree = ({ value = [], handleChange, fieldId, path }) => {
  const collections = Object.values(
    useSelector(state => state.collections.items)
  );
  const items = buildTree(
    collections,
    0,
    e =>
      handleChange(
        fieldId,
        value.includes(e) ? value.filter(x => e !== x) : [...value, e]
      ),
    value
  );
  return (
    <>
      <EuiTreeView
        display="compressed"
        items={items}
        aria-label="Sample Folder Tree"
      />
      <EuiSpacer size="m" />
    </>
  );
};

const Fields = { TextInput, MultiSelect, Facet, Tree, Range, Boolean };

const FilterField = ({
  field: { label, path, id, widget },
  value,
  handleChange
}) => {
  const Field = Fields[widget];
  return (
    <EuiForm>
      <EuiFormRow compressed label={label}>
        <Field
          label={label}
          fieldId={id}
          value={value}
          handleChange={handleChange}
          path={path}
        ></Field>
      </EuiFormRow>
    </EuiForm>
  );
};

export default FilterField;

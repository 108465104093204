import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Page from "components/Page";

import { EuiButton } from "@elastic/eui";
import { useAddQueryNavigation } from "helpers/hooks/url";
import ListUsers from "./List";

const NewUserButton = () => {
  const query = useAddQueryNavigation();
  return (
    <EuiButton
      fill
      iconType="plusInCircle"
      onClick={() => query("createUser", true)}
    >
      Benutzer hinzufügen
    </EuiButton>
  );
};

const Users = () => {
  const match = useRouteMatch();
  return (
    <Page title="Benutzer" actions={<NewUserButton />}>
      <Switch>
        <Route path={`${match.url}/List`}>
          <ListUsers />
        </Route>
        <Route path={`${match.url}`}>
          <ListUsers />
        </Route>
      </Switch>
    </Page>
  );
};

export default Users;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  EuiAccordion,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  EuiButtonIcon,
  EuiButton
} from "@elastic/eui";
import { useAddQueryNavigation } from "helpers/hooks/url";
import FilterField from "./Filters";
import { useFormik } from "formik";
import { set, reset } from "ducks/ui/filters";
const FilterBar = ({ className }) => {
  const addQuery = useAddQueryNavigation();
  const dispatch = useDispatch();
  const filters = useSelector(state => Object.values(state.filters.items));
  const filterValues = useSelector(state => state.uiFilters);
  const { values, setFieldValue, handleSubmit, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: values => {
      dispatch(set(values));
    }
  });
  return (
    <>
      <EuiTitle size="m">
        <h3>Filter</h3>
      </EuiTitle>
      <EuiSpacer size="l" />
      {filters.map(e => (
        <EuiAccordion
          initialIsOpen={false}
          key={`filterGroup${e.id}`}
          id={`filterGroup${e.id}`}
          className="euiAccordionForm"
          buttonClassName="euiAccordionForm__button"
          buttonContent={<ButtonContent title={e.label} />}
          extraAction={
            <ExtraAction onClick={() => addQuery("editFilter", e.id)} />
          }
        >
          {e.fields.map(field => (
            <FilterField
              key={field.id}
              field={field}
              value={values?.[field.id]}
              handleChange={setFieldValue}
            />
          ))}
        </EuiAccordion>
      ))}
      <EuiSpacer size="m" />
      <EuiButton size="s" onClick={handleSubmit}>
        Suchen
      </EuiButton>
      &emsp;
      <EuiButton
        size="s"
        onClick={() => {
          dispatch(reset());
        }}
        color={"danger"}
      >
        Reset
      </EuiButton>
    </>
  );
};

export default FilterBar;

const ButtonContent = ({ title }) => (
  <>
    <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false}>
      <EuiFlexItem>
        <EuiTitle size="xs" className="euiAccordionForm__title">
          <h6>{title}</h6>
        </EuiTitle>
      </EuiFlexItem>
    </EuiFlexGroup>
  </>
);

const ExtraAction = ({ onClick }) => {
  return (
    <EuiButtonIcon
      aria-label="Filter bearbeiten"
      iconType="documentEdit"
      color="subdued"
      className="euiAccordionForm__extraAction"
      onClick={onClick}
    />
  );
};

import React, { useEffect, useState } from "react";
import Page from "components/Page";
import Papa from "papaparse";
import Content from "components/Content";
import {
  EuiCodeBlock,
  EuiButton,
  EuiComboBox,
  EuiFilePicker
} from "@elastic/eui";
import { useSelector, useDispatch } from "react-redux";
import { create } from "ducks/entries";
import { EuiSpacer } from "@elastic/eui";
const Import = () => {
  const [result, setResult] = useState([]);
  const [collections, setCollections] = useState([]);
  const [file, setFile] = useState(false);
  const templates = useSelector(state => state.property_templates.items);
  const collectionOptions = useSelector(state => state.collections.items);

  const dispatch = useDispatch();

  const importData = () => {
    result.map(e => dispatch(create(e)));
  };

  useEffect(() => {
    async function getData() {
      // const response = await fetch("/Schulen.csv");
      // const reader = response.body.getReader();
      // const result = await reader.read(); // raw array
      // const decoder = new TextDecoder("utf-8");
      // const csv = decoder.decode(result.value); // the csv text

      const rows = file.data; // array of objects

      const templateHash = Object.values(templates).reduce((v, e) => {
        v[e.name] = e.id;
        return v;
      }, {});
      const importTemplate = file.meta.fields
        .filter(e => e.split(".").length > 1)
        .map(e => e.split(".")[0])
        .filter((v, i, a) => a.indexOf(v) === i)
        .map(e => templates[templateHash[e]])
        .filter(e => e !== undefined);
      const imports = rows.map(row => ({
        collection_ids: collections.map(e => e.id),
        geojson: row["longitude"]
          ? {
              type: "POINT",
              coordinates: [row["longitude"], row["latitude"]]
            }
          : null,
        properties: importTemplate
          .map(t => ({
            property_template_id: t.id,
            data: t.fields.reduce((v, f, i) => {
              v[f.label] =
                row[[t.name, f.label].join(".")] ??
                (f.label === "Geocoded"
                  ? row["longitude"]
                    ? true
                    : false
                  : "#NV");
              return v;
            }, {})
          }))
          .filter(e => Object.values(e.data).some(e => e !== "" && e !== "#NV"))
      }));

      setResult(imports);
    }
    if (file) getData();
  }, [file, templates, collections]);
  return (
    <Page title="Import">
      <Content
        title="Vorschau"
        actions={<EuiButton onClick={() => importData()}>Import</EuiButton>}
      >
        <EuiFilePicker
          initialPromptText="Select or drag and drop multiple files"
          onChange={files => {
            Papa.parse(files[0], {
              header: true,
              complete: e => setFile(e)
            });
          }}
        />

        <EuiComboBox
          placeholder="Kollektionen"
          options={Object.values(collectionOptions).map(e => ({
            label: e.name,
            id: e.id
          }))}
          selectedOptions={collections}
          onChange={e => setCollections(e)}
          isClearable={true}
          data-test-subj="demoComboBox"
        />
        <EuiSpacer></EuiSpacer>
        <EuiCodeBlock language="json">
          {JSON.stringify(result, null, 2)}
        </EuiCodeBlock>
      </Content>
    </Page>
  );
};

export default Import;

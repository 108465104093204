import React from "react";
import { EuiGlobalToastList } from "@elastic/eui";
import { connect } from "react-redux";
import { remove } from "ducks/toasts";
const Toasts = ({ toasts, remove }) => {
  return (
    <EuiGlobalToastList
      toasts={toasts}
      toastLifeTimeMs={6000}
      dismissToast={remove}
    />
  );
};

export default connect(state => ({ toasts: state.toasts }), { remove })(Toasts);

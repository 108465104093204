import React from "react";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
import { destroy } from "ducks/collections";
import { connect } from "react-redux";
import ConfirmModal from "components/ConfirmModal";
const DestroyCollection = ({ destroy, id }) => {
  const closeDestroyModal = useRemoveQueryNavigation("deleteCollection");

  return (
    <ConfirmModal
      title="Kollektion löschen"
      confirm={() => {
        destroy(id);
        closeDestroyModal();
      }}
      close={closeDestroyModal}
    />
  );
};

export default connect(null, { destroy })(DestroyCollection);

import React from "react";
import FormModal from "components/FormModal";
import { v4 } from "uuid";

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiFieldText,
  EuiSelect,
  EuiSwitch,
  EuiPanel,
  EuiIcon,
  EuiButtonIcon
} from "@elastic/eui";
import {
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  euiDragDropReorder
} from "@elastic/eui";

const Form = ({ values, handleChange, ...props }) => {
  const onDragEnd = ({ source, destination }) => {
    if (source && destination) {
      const items = euiDragDropReorder(
        values.fields,
        source.index,
        destination.index
      );
      handleChange("fields", items);
    }
  };
  return (
    <FormModal title="Filter" {...props}>
      <EuiFormRow label="Label">
        <EuiFieldText
          name="label"
          value={values.label}
          onChange={e => handleChange("label", e.target.value)}
        />
      </EuiFormRow>

      <EuiFormRow label="Aktiv">
        <EuiSwitch
          checked={values.active}
          onChange={() => handleChange("active", !values.active)}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="Felder">
        <EuiDragDropContext onDragEnd={onDragEnd}>
          <EuiDroppable droppableId="DROPPABLE_AREA_BARE">
            {values.fields.map((e, idx) => (
              <EuiDraggable
                key={e.id}
                index={idx}
                draggableId={e.id}
                customDragHandle={true}
                spacing="m"
              >
                {provided => (
                  <Field
                    data={e}
                    provided={provided}
                    index={idx}
                    handleChange={handleChange}
                    fields={values.fields}
                  ></Field>
                )}
              </EuiDraggable>
            ))}
          </EuiDroppable>
        </EuiDragDropContext>
      </EuiFormRow>
      <EuiSpacer size="m" />

      <EuiFlexGroup justifyContent={"flexEnd"}>
        <EuiFlexItem grow={false}>
          <EuiButton
            onClick={() =>
              handleChange("fields", [
                ...values.fields,
                {
                  id: v4(),
                  label: "",
                  widget: "TextInput",
                  comperator: "regex",
                  path: ""
                }
              ])
            }
            iconType="listAdd"
          >
            Feld hinzufügen
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </FormModal>
  );
};

export default Form;

const Field = ({ data, provided, index, handleChange, fields }) => {
  const widgets = [
    "TextInput",
    "MultiSelect",
    "Facet",
    "Tree",
    "Range",
    "Boolean"
  ].map(e => ({
    text: e,
    value: e
  }));
  const comperators = ["regex", "is_in", "in_range", "equal", "is"].map(e => ({
    text: e,
    value: e
  }));

  return (
    <EuiPanel paddingSize="m">
      <EuiFlexGroup>
        <EuiFlexItem className="actions" grow={false}>
          <div {...provided.dragHandleProps}>
            <EuiIcon type="grab" />
          </div>
          <EuiFlexItem />
          <EuiButtonIcon
            aria-label="Filter löschen"
            color="danger"
            onClick={() =>
              handleChange(
                "fields",
                fields.filter(e => e.id !== data.id)
              )
            }
            iconType="trash"
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow fullWidth label="Label">
            <EuiFieldText
              fullWidth
              name={`fields.${index}.label`}
              value={data.label}
              onChange={e =>
                handleChange(`fields.${index}.label`, e.target.value)
              }
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label="Pfad">
            <EuiFieldText
              fullWidth
              name={`fields.${index}.path`}
              value={data.path}
              onChange={e =>
                handleChange(`fields.${index}.path`, e.target.value)
              }
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label="Widget">
            <EuiSelect
              fullWidth
              options={widgets}
              value={data.widget}
              onChange={e => {
                handleChange(`fields.${index}.widget`, e.target.value);
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth label="Comperator">
            <EuiSelect
              fullWidth
              options={comperators}
              value={data.comperator}
              onChange={e => {
                handleChange(`fields.${index}.comperator`, e.target.value);
              }}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

import React from "react";
import { EuiBasicTable } from "@elastic/eui";
import Content from "components/Content";

import { useSelector } from "react-redux";

import { useAddQueryNavigation } from "helpers/hooks/url";
import { useDownload } from "helpers/hooks/file";
const List = () => {
  const files = useSelector(state => state.files.items);

  const addQuery = useAddQueryNavigation();

  const download = useDownload();

  const actions = [
    {
      name: "Löschen",
      description: "Diese Datei löschen",
      icon: "trash",
      color: "danger",
      type: "icon",
      onClick: e => {
        addQuery("deleteFile", e.id);
      }
    },
    {
      name: "Bearbeiten",
      isPrimary: true,
      description: "Diese Datei bearbeiten",
      icon: "pencil",
      type: "icon",

      onClick: e => {
        addQuery("editFile", e.id);
      }
    },
    {
      name: "Herunterladen",
      isPrimary: true,
      description: "Diese Datei herunterladen",
      icon: "save",
      type: "icon",

      onClick: e => {
        download(e);
      }
    }
  ];

  const columns = [
    { field: "id", name: "#" },
    { field: "name", name: "Name" },
    { field: "mimetype", name: "mimetype" },
    { field: "key", name: "key" },

    { field: "updated_at", name: "Letztes Update", dataType: "date" },
    { field: "created_at", name: "Erstellt am", dataType: "date" },

    { name: "Aktionen", actions }
  ];

  return (
    <Content title="Übersicht">
      <EuiBasicTable items={Object.values(files)} columns={columns} />
    </Content>
  );
};

export default List;

import React from "react";
import {
  EuiFlexItem,
  EuiFlexGroup,
  EuiText,
  EuiButton,
  EuiEmptyPrompt
} from "@elastic/eui";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { extraFullEntries } from "selectors/entries";
import Page from "components/Page";
import PropertyTabs from "./PropertyTabs";
import { useAddQueryNavigation } from "helpers/hooks/url";
import { useHistory } from "react-router-dom";
import MapPanel from "./MapPanel";
const Read = () => {
  const { id } = useParams();
  const entry = useSelector(extraFullEntries)[id];
  const collections = useSelector(state => state.collections.items);
  const addQuery = useAddQueryNavigation();
  const history = useHistory();
  if (entry === undefined)
    return (
      <EuiEmptyPrompt
        iconType="editorStrike"
        title={<h2>Eintrag nicht gefunden</h2>}
        actions={
          <EuiButton
            oncolor="primary"
            fill
            onClick={() => history.push("/entries")}
          >
            Zur Übersicht
          </EuiButton>
        }
      />
    );

  return (
    <Page
      title="Eintrag"
      actions={
        <>
          <EuiButton onClick={() => addQuery("editEntry", entry.id)}>
            Bearbeiten
          </EuiButton>{" "}
          <EuiButton
            color="danger"
            onClick={() => addQuery("deleteEntry", entry.id)}
          >
            Löschen
          </EuiButton>
        </>
      }
    >
      {entry.entryFormat && (
        <EuiText
          style={{
            marginBottom: "24px",
            borderBottom: "1px solid rgb(211, 218, 230)",
            borderTop: "1px solid rgb(211, 218, 230)",
            padding: "5px 0px"
          }}
        >
          {entry.entryFormat.map((e, i) => (
            <p style={{ marginBottom: "2px", fontWeight: "600" }}>{e}</p>
          ))}
        </EuiText>
      )}

      <EuiFlexGroup wrap gutterSize="s">
        <EuiFlexItem style={{ minWidth: "421px" }}>
          <PropertyTabs
            entry={entry}
            templates={entry.propertyTemplates}
          ></PropertyTabs>
        </EuiFlexItem>
        <EuiFlexItem style={{ minWidth: "421px" }}>
          <MapPanel id={entry.id} />

          {/* <Content paddingSize="m" title="Raw">
            <EuiCodeBlock language="json">
              {JSON.stringify(entry, null, 2)}
            </EuiCodeBlock>
          </Content> */}
        </EuiFlexItem>
      </EuiFlexGroup>
    </Page>
  );
};

export default Read;

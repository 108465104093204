import React from "react";
import Form from "./Form";
import { useFormik } from "formik";
import { update } from "ducks/collections";
import { add } from "ducks/toasts";

import { connect } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
const EditCollection = ({ add, update, collection }) => {
  const onClose = useRemoveQueryNavigation("editCollection");
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      withFormat: collection?.format ? true : false,
      ...collection
    },
    onSubmit: ({ withFormat, format, ...rest }) => {
      update({ format: withFormat ? JSON.stringify(format) : null, ...rest });
      add(
        "Update erfolgreich",
        "Benutzer wurde erfolgreich aktualisiert",
        "success",
        "user"
      );
    }
  });

  return values.name ? (
    <Form
      subtitle="Einstellungen bearbeiten"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
    ></Form>
  ) : (
    false
  );
};

export default connect(
  (state, { id }) => ({ collection: state.collections.items[id] }),
  { update, add }
)(EditCollection);

import React, { useEffect } from "react";
import { BadgeColumn } from "components/Columns";
import { EuiInMemoryTable, EuiSearchBar, EuiSpacer } from "@elastic/eui";
import { useHistory } from "react-router-dom";

const NeighboursTable = ({ data, query, setQuery }) => {
  const history = useHistory();

  const kollisionOptions = data
    .map(e => e.regel)
    .flat()
    .filter((e, i, a) => a.indexOf(e) === i)
    .map(e => ({ name: e, value: e }));
  const kollektionOptions = data
    .map(e => e.kollektion)
    .flat()
    .filter((e, i, a) => a.indexOf(e) === i)
    .map(e => ({ name: e, value: e }));
  const schema = {
    strict: true,
    fields: {
      abstand: {
        type: "number"
      },
      kollektion: {
        type: "string"
      },
      regel: {
        type: "string"
      },
      kollision: {
        type: "boolean"
      },
      eintrag: {
        type: "string"
      }
    }
  };
  const filters = [
    {
      type: "is",
      field: "kollision",
      name: "Kollision",
      negatedName: "Nachbar"
    },
    {
      type: "field_value_selection",
      field: "kollektion",
      name: "Kollektion",
      multiSelect: "or",
      //   cache: 10000, // will cache the loaded tags for 10 sec
      options: kollektionOptions
    },
    {
      type: "field_value_selection",
      field: "regel",
      name: "Regel",
      multiSelect: "or",
      //   cache: 10000, // will cache the loaded tags for 10 sec
      options: kollisionOptions
    }
  ];

  useEffect(
    () =>
      setQuery({
        ...query,
        result:
          query.queryText === "" || query.error
            ? data
            : EuiSearchBar.Query.execute(query.query, data, {
                defaultFields: ["eintrag", "regel", "kollektion"]
              })
      }),
    [data]
  );

  return (
    <>
      {/* <JSONBlock>{query}</JSONBlock> */}
      <EuiSearchBar
        box={{
          incremental: true,
          schema
        }}
        filters={filters}
        onChange={({ query, error, queryText }) => {
          setQuery({
            queryText: queryText,
            query: query,
            error: error,
            result: error
              ? []
              : EuiSearchBar.Query.execute(query, data, {
                  defaultFields: ["eintrag", "regel"]
                })
          });
        }}
      />
      <EuiSpacer size="s" />

      <EuiInMemoryTable
        // rowProps={e => ({ onMouseEnter: () => console.log(e) })}
        rowProps={row => ({
          onClick: function(e) {
            if (e.nativeEvent.target.nodeName !== "BUTTON") {
              history.push(`/entries/${row.id}`);
            }
          }
        })}
        pagination={true}
        compressed
        tableLayout="auto"
        allowNeutralSort={false}
        sorting={{
          sort: {
            field: "distance",
            direction: "asc"
          }
        }}
        items={query.queryText !== "" ? query.result : data}
        columns={[
          //   {
          //     name: "Eintrag",
          //     field: "properties",
          //     render: properties => <EntryFormatColumn entry={properties} />
          //   },
          //   {
          //     render: ids => <CollectionsColumn ids={ids} />,
          //     field: "collection_ids",
          //     name: "Kollektionen"
          //   },
          {
            name: "Eintrag",
            field: "eintrag",
            sortable: true
          },
          {
            render: e => <BadgeColumn entries={e} truncate={5} />,
            name: "Kollektion",
            field: "kollektion",
            sortable: true
          },
          {
            render: e => <BadgeColumn entries={e} truncate={5} />,
            name: "Regel",
            field: "regel",
            sortable: true
          },
          {
            sortable: true,
            name: "Abstand",
            field: "abstand",
            render: distance =>
              new Intl.NumberFormat("de-DE", {
                style: "unit",
                unit: "meter",
                maximumFractionDigits: 0
              }).format(distance)
          }
        ]}
      ></EuiInMemoryTable>
    </>
  );
};
export default NeighboursTable;

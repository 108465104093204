import React from "react";
import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiFormRow,
  EuiFieldText,
  EuiForm,
  EuiButton,
  EuiSpacer,
  EuiFieldPassword
} from "@elastic/eui";
import { useFormik } from "formik";
const Login = ({ error, login }) => {
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: { email: "", password: "" },
    onSubmit: values => login(values.email, values.password)
  });
  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageContent verticalPosition="center" horizontalPosition="center">
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle>
                <h2>Login</h2>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody style={{ width: "400px" }}>
            <form onSubmit={handleSubmit}>
              <EuiForm isInvalid={error} error={error}>
                <EuiFormRow label="E-Mail">
                  <EuiFieldText
                    values={values.email}
                    onChange={handleChange}
                    name="email"
                  />
                </EuiFormRow>
                <EuiFormRow label="Password">
                  <EuiFieldPassword
                    values={values.password}
                    onChange={handleChange}
                    name="password"
                  />
                </EuiFormRow>
                <EuiSpacer />

                <EuiButton type="submit" fill>
                  Login
                </EuiButton>
              </EuiForm>
            </form>
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};

export default Login;

export default state =>
  state.entries.isLoading ||
  state.collections.isLoading ||
  state.collisions.isLoading ||
  state.distance_rules.isLoading ||
  state.filters.isLoading ||
  state.neighbours.isLoading ||
  state.properties.isLoading ||
  state.property_templates.isLoading;

// state.files.isLoading ||

// state.users.isLoading ||

import React, { useEffect } from "react";
import { connect } from "react-redux";
import Login from "./Login";
import { login } from "ducks/auth";

import { list as listProperyTemplates } from "ducks/propertyTemplates";
import { list as listFilters } from "ducks/filters";
import { list as listCollections } from "ducks/collections";
import { list as listNeighbours } from "ducks/neighbours";
import { list as listCollisions } from "ducks/collisions";
import { list as listEntries } from "ducks/entries";
import { list as listDistanceRules } from "ducks/distanceRules";
import { list as listUsers } from "ducks/users";
import { list as listFiles } from "ducks/files";

const Auth = ({
  auth,
  children,
  login,
  listProperyTemplates,
  listFilters,
  listCollections,
  listNeighbours,
  listCollisions,
  listEntries,
  listDistanceRules,
  listUsers,
  listFiles
}) => {
  useEffect(() => {
    if (auth.token !== null) {
      listEntries();
      listCollections();
      listCollisions();
      listDistanceRules();
      listFilters();
      listNeighbours();
      listProperyTemplates();
      if (auth.data?.scope.includes("PROKSIMO:ADMIN")) {
        listUsers();
        listFiles();
      }
    }
  }, [auth.token]);

  return auth.token ? children : <Login login={login} error={auth.error} />;
};

export default connect(
  state => ({
    auth: state.auth
  }),
  {
    login,
    listProperyTemplates,
    listFilters,
    listCollections,
    listNeighbours,
    listCollisions,
    listEntries,
    listDistanceRules,
    listUsers,
    listFiles
  }
)(Auth);

import { useLocation, useHistory } from "react-router-dom";

export function useQuery() {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const urlArray = {};
  urlParams.forEach((v, k) => (urlArray[k] = v));
  return urlArray

}

export function useRemoveQueryNavigation(key) {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  query.delete(key);
  return () => history.push(`${location.pathname}?${query.toString()}`);
}

export function useAddQueryNavigation() {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  return (key, value) => {
    query.set(key, value);

    history.push(`${location.pathname}?${query.toString()}`);
  };
}

export function useAddQuery() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  return (key, value) => {
    query.set(key, value);
    return `${location.pathname}?${query.toString()}`;
  };
}

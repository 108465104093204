import React from "react";
import Form from "./Form";
import { useFormik } from "formik";
import { update } from "ducks/propertyTemplates";
import { add } from "ducks/toasts";

import { connect } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
const EditPropertTemplate = ({ add, update, propertyTemplate }) => {
  const onClose = useRemoveQueryNavigation("editPropertyTemplate");
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: propertyTemplate,
    onSubmit: values => {
      update(values);
      add(
        "Update erfolgreich",
        "Benutzer wurde erfolgreich aktualisiert",
        "success",
        "user"
      );
    }
  });

  return values ? (
    <Form
      subtitle="Einstellungen bearbeiten"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
    ></Form>
  ) : (
    false
  );
};

export default connect(
  (state, { id }) => ({ propertyTemplate: state.property_templates.items[id] }),
  { update, add }
)(EditPropertTemplate);

import React, { Fragment, useState } from "react";
import FormModal from "components/FormModal";
// import { GeoJsonLayer } from "@deck.gl/layers";

import { useSelector } from "react-redux";
// import { MapView } from "@deck.gl/core";
// import buffer from "@turf/buffer";
// import bbox from "@turf/bbox";
import { useAddQueryNavigation } from "helpers/hooks/url";
import "./style.css";
import {
  EuiButtonIcon,
  EuiFormRow,
  EuiSpacer,
  EuiAspectRatio,
  EuiComboBox,
  EuiButton,
  EuiTextArea
} from "@elastic/eui";
import GeoCodeField from "components/FormFields/GeoCodeField";
import { EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import MapField from "components/FormFields/MapField";
const Form = React.memo(({ values, handleChange, ...props }) => {
  const collections = useSelector(state => state.collections.items);
  const collectionOptions = Object.values(collections)
    .map(e => ({
      id: e.id,
      label: e.name
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const addQuery = useAddQueryNavigation();
  const [viewState, setViewState] = useState(false);
  return (
    <FormModal title="Eintrag" {...props}>
      <EuiFormRow>
        <EuiAspectRatio width={4} height={3}>
          <MapField
            value={values.geojson === null ? undefined : values.geojson}
            onChange={e => handleChange("geojson", e)}
            view={viewState}
          />
        </EuiAspectRatio>
      </EuiFormRow>
      <EuiFormRow>
        <GeoCodeField
          onChange={e => {
            const value = {
              type: "Point",
              coordinates: [e.data.Longitude, e.data.Latitude]
            };
            handleChange("geojson", value);
            setViewState(value);
          }}
        />
      </EuiFormRow>
      <EuiFormRow>
        <EuiTextArea
          onChange={e => handleChange("geojson", JSON.parse(e.target.value))}
          value={JSON.stringify(values.geojson)}
        />
      </EuiFormRow>
      <EuiFormRow label="Kollektionen">
        <EuiComboBox
          selectedOptions={values.collection_ids.map(e => ({
            label: collections[e].name,
            id: e
          }))}
          onChange={e => {
            handleChange(
              "collection_ids",
              e.map(x => x.id)
            );
          }}
          placeholder="Kollektionen auswählen"
          options={collectionOptions}
          isClearable={true}
        />
      </EuiFormRow>
      <EuiFormRow label="Daten">
        <div>
          {values.properties.map(e => (
            <Data key={e} id={e}></Data>
          ))}
        </div>
      </EuiFormRow>

      <EuiSpacer size="m" />

      <EuiFlexGroup justifyContent={"flexEnd"}>
        <EuiFlexItem grow={false}>
          <EuiButton
            size="s"
            onClick={() => addQuery("createProperty", values.id)}
            iconType="listAdd"
          >
            Feld hinzufügen
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </FormModal>
  );
});

const Data = ({ id }) => {
  const addQuery = useAddQueryNavigation();

  const data = useSelector(state => state.properties.items[id]);
  const format = useSelector(
    state => state.property_templates.items[data?.property_template_id]
  );
  if (format === undefined) return false;

  const items = format.fields.map(e => ({
    id: e.id,
    title: e.label,
    description: data.data[e.label]
  }));

  return (
    <EuiFlexGroup responsive={false}>
      <EuiFlexItem>
        <dl
          style={{
            fontSize: "12px",
            borderLeft: "2px solid black",
            paddingLeft: "5px",
            marginBottom: "7px"
          }}
        >
          {items.map(e => (
            <Fragment key={e.id}>
              <dt style={{ fontWeight: 600, marginBottom: "4px" }}>
                {e.title}
              </dt>
              <dd style={{ marginBottom: "8px" }}>{e.description}</dd>
            </Fragment>
          ))}
        </dl>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          aria-label="Datensatz löschen"
          iconSize="s"
          className="entriesButtons"
          color="danger"
          iconType="trash"
          flush="right"
          onClick={() => addQuery("deleteProperty", id)}
        />
        <EuiButtonIcon
          aria-label="Datensatz bearbeiten"
          iconSize="s"
          className="entriesButtons"
          color="primary"
          iconType="pencil"
          flush="right"
          onClick={() => addQuery("editProperty", id)}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Form;

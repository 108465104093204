import users from "./users";
import toasts from "./toasts";
import auth from "./auth";
import files from "./files";
import property_templates from "./propertyTemplates";
import collections from "./collections";
import filters from "./filters";
import entries from "./entries";
import properties from "./properties";
import uiFilters from "./ui/filters";
import distance_rules from "./distanceRules";
import neighbours from "./neighbours";
import collisions from "./collisions";
import entriesMap from "./ui/entriesMap";

export default {
  users,
  toasts,
  auth,
  files,
  property_templates,
  collections,
  filters,
  entries,
  properties,
  uiFilters,
  distance_rules,
  neighbours,
  collisions,
  entriesMap
};

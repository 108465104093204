import { createSlice } from "@reduxjs/toolkit";
import ducksHelper from "helpers/ducks";
import { schema } from "normalizr";
import { CALL_API } from "middleware/api";
const name = "entries";

const propertySchema = new schema.Entity("properties");
const entrieSchema = new schema.Entity("entries", {
  properties: [propertySchema]
});

const {
  names: { REQUEST, UPDATE, SUCCESS, FAILURE, CREATE, DELETE },
  defaultState,
  actions: { list, create, update, destroy }
} = ducksHelper(name, entrieSchema);

const slice = createSlice({
  name: name,
  initialState: defaultState,
  extraReducers: {
    "properties/delete": (state, { payload }) => {
      const { entry_id, id: property_id } = Object.values(
        payload.entities.properties
      )[0];
      state.items[entry_id].properties = state.items[
        entry_id
      ].properties.filter(e => e !== property_id);
    }
  },
  reducers: {
    delete: (state, action) => {
      const ids = Object.keys(action.payload.entities[name]);
      ids.forEach(e => {
        delete state.items[e];
      });
      state.updated_at = new Date();
      state.isLoading = false;
    },
    success: (state, action) => ({
      isLoading: false,
      update_at: new Date(),
      items: { ...state.items, ...action.payload.entities[name] }
    })
  }
});

const bulkUpdateCollectionIds = payload => ({
  [CALL_API]: {
    types: [UPDATE, SUCCESS, FAILURE],
    endpoint: `/${name}/bulk/collection_ids`,
    schema: [entrieSchema],
    method: "PATCH",
    body: payload
  }
});

export default slice.reducer;
export { update, list, create, destroy, bulkUpdateCollectionIds };

import React from "react";
import Form from "./Form";
import { useFormik } from "formik";
import { update } from "ducks/users";
import { add } from "ducks/toasts";

import { connect, useSelector } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
const EditUser = ({ add, update, id }) => {
  const onClose = useRemoveQueryNavigation("editUser");
  const user = useSelector(state => state.users.items[id]);
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: { ...user, password: "", passwordConfirmation: "" },
    onSubmit: values => {
      if (values.password === "") {
        delete values["password"];
        delete values["passwordConfirmation"];
      }
      update(values);
      add(
        "Update erfolgreich",
        "Benutzer wurde erfolgreich aktualisiert",
        "success",
        "user"
      );
    }
  });

  return values.username ? (
    <Form
      subtitle="Benutzer bearbeiten"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
    ></Form>
  ) : (
    false
  );
};

export default connect(null, { update, add })(EditUser);

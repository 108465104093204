import { schema as nschema } from "normalizr";
import { CALL_API } from "middleware/api";

export default function ducksHelper(name, schema) {
  const REQUEST = `${name}/request`;
  const CREATE = `${name}/create`;
  const UPDATE = `${name}/update`;
  const SUCCESS = `${name}/success`;
  const FAILURE = `${name}/failure`;
  const DELETE = `${name}/delete`;

  schema = schema ?? new nschema.Entity(name);
  const state = { items: {}, updated_at: null, isLoading: true };

  const list = () => ({
    [CALL_API]: {
      types: [REQUEST, SUCCESS, FAILURE],
      endpoint: `/${name}`,
      schema: [schema],
      method: "GET"
    }
  });

  const update = element => ({
    [CALL_API]: {
      types: [UPDATE, SUCCESS, FAILURE],
      endpoint: `/${name}/${element.id}`,
      schema: [schema],
      method: "PATCH",
      body: element
    }
  });

  const create = element => ({
    [CALL_API]: {
      types: [CREATE, SUCCESS, FAILURE],
      endpoint: `/${name}`,
      schema: [schema],
      method: "POST",
      body: element
    }
  });

  const destroy = id => ({
    [CALL_API]: {
      types: [REQUEST, DELETE, FAILURE],
      endpoint: `/${name}/${id}`,
      schema: [schema],
      method: "DELETE"
    }
  });

  return {
    names: {
      REQUEST,
      UPDATE,
      SUCCESS,
      FAILURE,
      CREATE,
      DELETE
    },
    schema,
    defaultState: state,
    actions: { list, create, update, destroy }
  };
}

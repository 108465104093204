import React from "react";
import FormModal from "components/FormModal";

import { EuiFormRow, EuiFieldText, EuiComboBox } from "@elastic/eui";
const Form = ({ values, handleChange, ...props }) => {
  const options = [
    { label: "PROKSIMO:ADMIN" },
    { label: "PROKSIMO:ENTRIES" },
    { label: "PROKSIMO:FILES" },
    { label: "PROKSIMO:USERS" }
  ];
  return (
    <FormModal title="Benutzer" {...props}>
      <EuiFormRow label="Benutzername">
        <EuiFieldText
          name="username"
          value={values.username}
          onChange={e => handleChange("username", e.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow label="E-Mail">
        <EuiFieldText
          name="email"
          value={values.email}
          onChange={e => handleChange("email", e.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow label="Scope">
        <EuiComboBox
          selectedOptions={values.scope.map(e => ({ label: e }))}
          placeholder="Scope auswählen"
          onChange={e =>
            handleChange(
              "scope",
              e.map(x => x.label)
            )
          }
          isClearable={true}
          data-test-subj="demoComboBox"
          options={options}
        />
      </EuiFormRow>
      <EuiFormRow label="Password">
        <EuiFieldText
          name="password"
          value={values.password}
          onChange={e => handleChange("password", e.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow label="Password bestätigen">
        <EuiFieldText
          name="passwordConfirmation"
          value={values.passwordConfirmation}
          onChange={e => handleChange("passwordConfirmation", e.target.value)}
        />
      </EuiFormRow>
    </FormModal>
  );
};

export default Form;

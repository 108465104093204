import React from "react";
import { EuiBasicTable } from "@elastic/eui";

import { useSelector } from "react-redux";
import { useAddQueryNavigation } from "helpers/hooks/url";
import Content from "components/Content";

const List = () => {
  const users = useSelector(state => state.users.items);

  const addQuery = useAddQueryNavigation();

  const actions = [
    {
      name: "Löschen",
      description: "Diesen Benutzer löschen",
      icon: "trash",
      color: "danger",
      type: "icon",
      onClick: e => {
        addQuery("deleteUser", e.id);
      }
    },
    {
      name: "Edit",
      isPrimary: true,
      description: "Diesen Benutzer bearbeiten",
      icon: "pencil",
      type: "icon",

      onClick: e => {
        addQuery("editUser", e.id);
      }
    }
  ];

  const columns = [
    { field: "id", name: "#" },
    { field: "username", name: "Benutzername" },
    { field: "email", name: "E-Mail" },
    { field: "scope", name: "Scopes" },

    { field: "updated_at", name: "Letztes Update", dataType: "date" },
    { field: "created_at", name: "Erstellt am", dataType: "date" },

    { name: "Aktionen", actions }
  ];

  return (
    <Content title="Übersicht">
      <EuiBasicTable items={Object.values(users)} columns={columns} />
    </Content>
  );
};

export default List;

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Page from "components/Page";

import { EuiButton } from "@elastic/eui";

import { useAddQueryNavigation } from "helpers/hooks/url";
import ListFiles from "./List";

const NewFileButton = () => {
  const query = useAddQueryNavigation();

  return (
    <EuiButton
      fill
      iconType="plusInCircle"
      onClick={() => query("createFile", true)}
    >
      Datei hinzufügen
    </EuiButton>
  );
};

const Users = () => {
  const match = useRouteMatch();
  return (
    <Page title="Dateien" actions={<NewFileButton />}>
      <Switch>
        <Route path={`${match.url}/List`}>
          <ListFiles />
        </Route>
        <Route path={`${match.url}`}>
          <ListFiles />
        </Route>
      </Switch>
    </Page>
  );
};

export default Users;

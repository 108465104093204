import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Page from "components/Page";
import { useAddQueryNavigation } from "helpers/hooks/url";
import { EuiButton } from "@elastic/eui";
import ListFiles from "./List";

const NewFilterButton = () => {
  const query = useAddQueryNavigation();

  return (
    <EuiButton
      fill
      iconType="plusInCircle"
      onClick={() => query("createFilter", true)}
    >
      Filter hinzufügen
    </EuiButton>
  );
};

const Filters = () => {
  const match = useRouteMatch();
  return (
    <Page title="Filter" actions={<NewFilterButton />}>
      <Switch>
        <Route path={`${match.url}/List`}>
          <ListFiles />
        </Route>
        <Route path={`${match.url}`}>
          <ListFiles />
        </Route>
      </Switch>
    </Page>
  );
};

export default Filters;

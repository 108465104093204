import React from "react";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
import { destroy } from "ducks/files";
import { connect } from "react-redux";
import ConfirmModal from "components/ConfirmModal";
const DeleteFile = ({ deleteFile, id }) => {
  const closeDestroyModal = useRemoveQueryNavigation("deleteFile");

  return (
    <ConfirmModal
      title="Dtatei wirklich löschen?"
      confirm={() => {
        deleteFile(id);
        closeDestroyModal();
      }}
      close={closeDestroyModal}
    />
  );
};

export default connect(null, { deleteFile: destroy })(DeleteFile);

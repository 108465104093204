import React from "react";
import Logo from "./logo.svg";
import {
  EuiHeader,
  EuiHeaderBreadcrumbs,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiHeaderLogo,
  EuiIcon,
  EuiShowFor
} from "@elastic/eui";
import User from "./User";
import { useLocation, useHistory } from "react-router-dom";
const Header = ({ navRef }) => {
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname.split("/");
  return (
    <EuiHeader>
      <EuiShowFor sizes={["xs", "s"]}>
        <EuiHeaderSectionItem border="right">
          <EuiHeaderSectionItemButton
            aria-label="Open nav"
            onClick={() => navRef.current.toggleOpen()}
          >
            <EuiIcon type="apps" href="#" size="m" />
          </EuiHeaderSectionItemButton>
        </EuiHeaderSectionItem>
      </EuiShowFor>

      <EuiHeaderSection grow={false}>
        <EuiHeaderSectionItem border="right">
          <EuiHeaderLogo iconTitle="Proksimo" iconType={Logo} />
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem border="right"></EuiHeaderSectionItem>
      </EuiHeaderSection>
      <EuiHeaderSection grow={true}></EuiHeaderSection>

      {/* <EuiHeaderBreadcrumbs
        breadcrumbs={path.slice(1).map((e, i) => ({
          text: e === "" ? "Dashboard" : e,
          onClick: () => history.push(path.slice(0, i + 2).join("/"))
        }))}
      /> */}
      <EuiHeaderSection side="right">
        {/* <EuiHeaderSectionItem>{this.renderSearch()}</EuiHeaderSectionItem> */}

        <EuiHeaderSectionItem>
          <User />
        </EuiHeaderSectionItem>

        {/* <EuiHeaderSectionItem>
          <HeaderAppMenu />
        </EuiHeaderSectionItem> */}
      </EuiHeaderSection>
    </EuiHeader>
  );
};
export default Header;

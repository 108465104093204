import React, { useState, useCallback, useRef, useEffect } from "react";
import DeckGL from "@deck.gl/react";
import { StaticMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { setViewState, setPicked as _setPicked } from "ducks/ui/entriesMap";
import "./style.scss";
import useEntriesLayer from "./Layers/useEntriesLayer";
import Hover from "components/MapHover";
import MapPanel from "./MapPanel";
import bbox from "@turf/bbox";
import { geocode } from "helpers/hooks/useGeocode";
import { fitBounds } from "@math.gl/web-mercator";
import { neighboursLookUp } from "helpers/hooks/useNeighboursLookUp";
import { IconLayer, ScatterplotLayer } from "@deck.gl/layers";
import icons from "routes/Entries/Read/MapPanel/sprite.png";

const EntriesMap = () => {
  const mapRef = useRef();
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const mapState = useSelector(state => state.entriesMap);
  const [entriesLayer, data] = useEntriesLayer();
  const [hover, setHover] = useState(false);
  const setPicked = e => {
    dispatch(_setPicked(e));
  };

  function handleHover(data) {
    if (data?.picked) {
      const [x, y] =
        data.object.geometry.type === "Point"
          ? data.layer.context.viewport.project(
              data.object.geometry.coordinates
            )
          : [data.x, data.y];
      setHover({ x, y, data: data.object.properties.entryFormat });
    } else setHover(false);
  }
  function handlePicked(data) {
    if (data.picked === undefined) {
      geocode([data.coordinate[1], data.coordinate[0]], token).then(b => {
        if (b.length > 0) {
          const e = b[0];
          const entry = {
            type: "Point",
            coordinates: data.coordinate
          };
          neighboursLookUp(JSON.stringify(entry), 1000, token).then(x => {
            const result = {
              source: "Search",
              coordinates: data.coordinate,
              neighbours: { results: x.results, total: x.total },
              collisions: { distancerules: {} },
              collectionNames: [],
              entryFormat: [
                `${e.Straße} ${e.Hausnummer}`,
                `${e.Postleitzahl} ${e.Ort}`
              ]
            };
            setPicked(result);
          });
        }
      });
    } else {
      const pickedData = {
        coordinates: data.coordinate,
        id: data.object.properties.id,
        source: "Entry"
      };
      setPicked(pickedData);
    }
  }

  const setIntialView = useCallback(() => {
    if (data.features.length === 0) return;
    const { width, height } = mapRef.current.deck;
    const box = bbox(data);
    const viewState = fitBounds({
      width,
      height,
      bounds: [
        [box[0], box[1]],
        [box[2], box[3]]
      ],
      padding: 30
    });
    dispatch(setViewState(viewState));
  }, []);

  const ICON_MAPPING = {
    marker: { x: 196, y: 56, width: 16, height: 28, mask: true }
  };
  const icon = new IconLayer({
    id: "entryIcon",
    data: mapState.picked ? [mapState.picked.coordinates] : [],

    pickable: false,

    iconAtlas: icons,
    iconMapping: ICON_MAPPING,
    getIcon: d => "marker",
    sizeMinPixels: 30,
    getPosition: d => d,
    getColor: d => [14, 90, 138]
  });
  const radius = new ScatterplotLayer({
    id: "radius",
    data: mapState.picked ? [mapState.picked.coordinates] : [],
    opacity: 0.2,

    pickable: false,
    getPosition: d => d,
    getRadius: d => mapState.radius,
    getColor: d => [14, 90, 138]
  });

  return (
    <div className="entriesMap">
      <DeckGL
        // onLoad={setIntialView}
        onHover={handleHover}
        ref={mapRef}
        layers={[entriesLayer, icon, radius]}
        controller={true}
        viewState={mapState.viewState}
        onViewStateChange={e => dispatch(setViewState(e.viewState))}
        onClick={handlePicked}
      >
        <StaticMap />
      </DeckGL>
      {hover && <Hover x={hover.x} y={hover.y} data={hover.data}></Hover>}
      {<MapPanel data={mapState.picked} />}
    </div>
  );
};

export default EntriesMap;

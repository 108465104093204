import React from "react";
import Form from "./Form";
import { useFormik } from "formik";
import { create } from "ducks/users";
import { add } from "ducks/toasts";

import { connect } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";

const CreateUser = ({ add, create }) => {
  const onClose = useRemoveQueryNavigation("createUser");

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      scope: []
    },
    onSubmit: values => {
      create(values);
      add(
        "Update erfolgreich",
        "Benutzer wurde erfolgreich erstellt",
        "success",
        "user"
      );
    }
  });

  return (
    <Form
      subtitle="Neuen Benutzer erstellen"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
    ></Form>
  );
};

export default connect(null, { create, add })(CreateUser);

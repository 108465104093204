import React from "react";
import {
  EuiPageContent,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiPageContentHeader,
  EuiPageContentBody
} from "@elastic/eui";
const Content = ({ title, actions, children, ...props }) => {
  return (
    <EuiPageContent style={{ overflow: "auto" }} {...props}>
      <EuiPageContentHeader>
        <EuiPageContentHeaderSection>
          <EuiTitle size="s">
            <h3>{title}</h3>
          </EuiTitle>
        </EuiPageContentHeaderSection>
        <EuiPageContentHeaderSection>{actions}</EuiPageContentHeaderSection>
      </EuiPageContentHeader>
      <EuiPageContentBody>{children}</EuiPageContentBody>
    </EuiPageContent>
  );
};

export default Content;

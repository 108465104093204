import { normalize } from "normalizr";
import axios from "axios";

const httpClient = axios.create();

httpClient.defaults.timeout = 5 * 1000 * 60;

const API_ROOT = process.env.REACT_APP_API_ENDPOINT;

const callApi = (endpoint, schema, method = "GET", body, token = false) => {
  const fullUrl =
    endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

  const headers = {
    Authorization: `Bearer ${token}`
  };
  if (!(body instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }
  return httpClient({
    url: fullUrl,
    method,
    headers,
    data: body instanceof FormData ? body : JSON.stringify(body)
  })
    .then(json => {
      return schema
        ? Object.assign({}, normalize(json.data, schema))
        : json.data;
    })
    .catch(json => Promise.reject(json));
};

export const CALL_API = "Call API";

export default store => next => action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }
  const token = store.getState()?.auth?.token;

  let { endpoint } = callAPI;
  const { schema, types, method, body } = callAPI;

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every(type => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const [requestType, successType, failureType] = types;
  next({ type: requestType });

  return callApi(endpoint, schema, method, body, token).then(
    response =>
      next({
        payload: response,
        type: successType
      }),
    error =>
      next({
        type: failureType,
        error: error.message || "Something bad happened"
      })
  );
};

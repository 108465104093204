export default function wrapGeo(d) {
  const data = Array.isArray(d) ? d : [d];
  return {
    type: "FeatureCollection",
    features: data.map(e => ({
      type: "Feature",
      properties: {
        ...e.properties
      },
      geometry: e.geo
    }))
  };
}

export function wrapGeoJson(d) {
  const data = Array.isArray(d) ? d : [d];
  return {
    type: "FeatureCollection",
    features: data
      .filter(({ geojson }) => geojson)
      .map(({ geojson, ...properties }) => ({
        type: "Feature",
        properties,
        geometry: geojson
      }))
  };
}

import React, { useState } from "react";
import {
  EuiButton,
  EuiPopover,
  EuiComboBox,
  EuiFormRow,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from "@elastic/eui";
import { useSelector, useDispatch } from "react-redux";
import { bulkUpdateCollectionIds } from "ducks/entries";
const CollectionChanger = ({ entries }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  return (
    <EuiPopover
      id="popover"
      ownFocus
      button={
        <EuiButton onClick={() => setOpen(!open)}>
          Kollektionen festlegen
        </EuiButton>
      }
      isOpen={open}
      closePopover={() => setOpen(false)}
    >
      <div style={{ width: "300px" }}>
        <EuiFormRow>
          <CollectionComboBox
            onChange={setSelected}
            selected={selected}
          ></CollectionComboBox>
        </EuiFormRow>
        <EuiSpacer></EuiSpacer>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiButton
              onClick={() =>
                dispatch(
                  bulkUpdateCollectionIds({
                    mode: "add",
                    entry_ids: entries.map(e => e.id),
                    collection_ids: selected.map(e => e.value)
                  })
                )
              }
              size="s"
            >
              Hinzufügen
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton
              onClick={() =>
                dispatch(
                  bulkUpdateCollectionIds({
                    mode: "delete",
                    entry_ids: entries.map(e => e.id),
                    collection_ids: selected.map(e => e.value)
                  })
                )
              }
              size="s"
            >
              Entfernen
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  );
};

export default CollectionChanger;

const CollectionComboBox = ({ onChange, selected }) => {
  const collections = useSelector(state => state.collections.items);
  return (
    <EuiComboBox
      placeholder="Kollektionen auswählen"
      options={Object.values(collections).map(e => ({
        label: e.name,
        value: e.id
      }))}
      selectedOptions={selected}
      onChange={onChange}
    />
  );
};

import React from "react";
import Form from "./Form";
import { useFormik } from "formik";
import { create } from "ducks/collections";
import { add } from "ducks/toasts";

import { connect } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
const CreateCollection = ({ add, create }) => {
  const onClose = useRemoveQueryNavigation("createCollection");
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      meta: {},
      color: [211, 96, 134, 100],
      format: null,
      parent_id: null,
      withFormat: false
    },
    onSubmit: ({ withFormat, format, ...rest }) => {
      create({ format: withFormat ? JSON.stringify(format) : null, ...rest });
      add(
        "Update erfolgreich",
        "Benutzer wurde erfolgreich aktualisiert",
        "success",
        "user"
      );
    }
  });

  return values ? (
    <Form
      subtitle="Neuen Kollektion erstellen"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
    ></Form>
  ) : (
    false
  );
};

export default connect(null, { create, add })(CreateCollection);

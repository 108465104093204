import React from "react";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
import { destroyWithEntry } from "ducks/properties";
import { connect } from "react-redux";
import ConfirmModal from "components/ConfirmModal";
const DestroyProperty = ({ destroyWithEntry, id }) => {
  const closeDestroyModal = useRemoveQueryNavigation("deleteProperty");

  return (
    <ConfirmModal
      title="Datensatz löschen"
      confirm={() => {
        destroyWithEntry(id);
        closeDestroyModal();
      }}
      close={closeDestroyModal}
    />
  );
};

export default connect(null, { destroyWithEntry })(DestroyProperty);

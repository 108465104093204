import React from "react";
import { v4 } from "uuid";
import FormModal from "components/FormModal";

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiFieldText,
  EuiSelect,
  EuiComboBox,
  EuiTextArea,
  EuiPanel,
  EuiIcon,
  EuiButtonIcon
} from "@elastic/eui";

import {
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  euiDragDropReorder
} from "@elastic/eui";
import "./style.css";
const Form = ({ values, handleChange, ...props }) => {
  const onDragEnd = ({ source, destination }) => {
    if (source && destination) {
      const items = euiDragDropReorder(
        values.fields,
        source.index,
        destination.index
      );
      handleChange("fields", items);
    }
  };

  return (
    <FormModal title="Datenformat" {...props} size="m">
      <EuiFormRow fullWidth label="Name">
        <EuiFieldText
          fullWidth
          name="name"
          value={values.name}
          onChange={e => handleChange("name", e.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="Beschreibung">
        <EuiTextArea
          fullWidth
          placeholder="Beschreibung"
          value={values.description}
          onChange={e => handleChange("description", e.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="Felder">
        <EuiDragDropContext onDragEnd={onDragEnd}>
          <EuiDroppable droppableId="DROPPABLE_AREA_BARE">
            {values.fields.map((e, idx) => (
              <EuiDraggable
                key={e.id}
                index={idx}
                draggableId={e.id}
                customDragHandle={true}
                spacing="m"
              >
                {provided => (
                  <Field
                    data={e}
                    provided={provided}
                    index={idx}
                    handleChange={handleChange}
                    fields={values.fields}
                  ></Field>
                )}
              </EuiDraggable>
            ))}
          </EuiDroppable>
        </EuiDragDropContext>
      </EuiFormRow>
      <EuiSpacer size="m" />

      <EuiFlexGroup justifyContent={"flexEnd"}>
        <EuiFlexItem grow={false}>
          <EuiButton
            onClick={() =>
              handleChange("fields", [
                ...values.fields,
                {
                  id: v4(),
                  label: "",
                  type: "0"
                }
              ])
            }
            iconType="listAdd"
          >
            Feld hinzufügen
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </FormModal>
  );
};

export default Form;

const Field = ({ data, provided, index, handleChange, fields }) => {
  const options = [
    { text: "Zeichenfolge", value: "0" },
    { text: "Zahl", value: "1" },
    { text: "Wahr/Falsch", value: "2" },
    { text: "Datum", value: "3" },
    { text: "Datum/Uhrzeit", value: "7" },
    // { text: "JSON", value: 4 },
    { text: "Auswahl", value: "5" }
    // { text: "Datei", value: 6 },
    // { text: "Volltext", value: 8 }
  ];

  return (
    <EuiPanel paddingSize="m">
      <EuiFlexGroup>
        <EuiFlexItem className="actions" grow={false}>
          <div {...provided.dragHandleProps}>
            <EuiIcon type="grab" />
          </div>
          <EuiFlexItem />
          <EuiButtonIcon
            aria-label="Datensatz löschen"
            color="danger"
            onClick={() =>
              handleChange(
                "fields",
                fields.filter(e => e.id !== data.id)
              )
            }
            iconType="trash"
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow fullWidth label="Label">
            <EuiFieldText
              fullWidth
              name={`fields.${index}.label`}
              value={data.label}
              onChange={e =>
                handleChange(`fields.${index}.label`, e.target.value)
              }
            />
          </EuiFormRow>

          <EuiFormRow fullWidth label="Art">
            <EuiSelect
              fullWidth
              options={options}
              value={data.type}
              onChange={e => {
                handleChange(`fields.${index}.type`, e.target.value);
                handleChange(`fields.${index}.options`, undefined);
              }}
            />
          </EuiFormRow>
          {data.type === "5" && (
            <EuiFormRow fullWidth label="Optionen">
              <EuiComboBox
                fullWidth
                noSuggestions
                selectedOptions={(data.options ?? []).map(e => ({ label: e }))}
                onCreateOption={(e, flattenOptions) =>
                  handleChange(
                    `fields.${index}.options`,
                    (data.options ?? []).concat(e)
                  )
                }
                onChange={e =>
                  handleChange(
                    `fields.${index}.options`,
                    e.map(e => e.label)
                  )
                }
              />
            </EuiFormRow>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

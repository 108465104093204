import React from "react";
import {
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiPageSideBar
} from "@elastic/eui";
import "./style.css";
const Page = ({ title, children, actions, sidebar }) => {
  return (
    <EuiPage style={{ width: "100%" }} className="euiNavDrawerPage page">
      {sidebar && (
        <EuiPageSideBar
          style={{ overflow: "auto", minWidth: "330px" }}
          className="euiNavDrawerPage__pageBody"
        >
          {sidebar}
        </EuiPageSideBar>
      )}
      <EuiPageBody className={sidebar ? "" : "euiNavDrawerPage__pageBody"}>
        <EuiPageHeader>
          <EuiPageHeaderSection>
            <EuiTitle size="m">
              <h2>{title}</h2>
            </EuiTitle>
          </EuiPageHeaderSection>
          <EuiPageHeaderSection>{actions}</EuiPageHeaderSection>
        </EuiPageHeader>

        {children}
      </EuiPageBody>
    </EuiPage>
  );
};

export default Page;

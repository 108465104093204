import { createSlice } from "@reduxjs/toolkit";
import ducksHelper from "helpers/ducks";
import { CALL_API } from "middleware/api";
import { schema } from "normalizr";

const name = "properties";

const {
  // names: { REQUEST, UPDATE, SUCCESS, FAILURE, CREATE, DELETE },
  defaultState,
  actions: { list, create, update, destroy }
} = ducksHelper(name);

const slice = createSlice({
  name: name,
  initialState: defaultState,
  extraReducers: {
    "entries/success": (state, action) => ({
      isLoading: false,
      update_at: new Date(),
      items: { ...state.items, ...action.payload.entities[name] }
    })
  },
  reducers: {
    delete: (state, action) => {
      const ids = Object.keys(action.payload.entities[name]);
      ids.forEach(e => {
        delete state.items[e];
      });
      state.updated_at = new Date();
      state.isLoading = false;
    },
    success: (state, action) => ({
      isLoading: false,
      update_at: new Date(),
      items: { ...state.items, ...action.payload.entities[name] }
    })
  }
});

export default slice.reducer;

const propertySchema = new schema.Entity("properties");
const entrieSchema = new schema.Entity("entries", {
  properties: [propertySchema]
});

export const updateWithEntry = values => async dispatch => {
  await dispatch(update(values));
  await dispatch({
    [CALL_API]: {
      types: ["entries/request", "entries/success", "entries/failure"],
      endpoint: `/entries/${values.entry_id}`,
      schema: [entrieSchema],
      method: "GET"
    }
  });
};

export const destroyWithEntry = values => async dispatch => {
  const response = await dispatch(destroy(values));

  await dispatch({
    [CALL_API]: {
      types: ["entries/request", "entries/success", "entries/failure"],
      endpoint: `/entries/${
        Object.values(response.payload.entities.properties)[0].entry_id
      }`,
      schema: [entrieSchema],
      method: "GET"
    }
  });
};

export const createWithEntry = values => async dispatch => {
  await dispatch(create(values));

  await dispatch({
    [CALL_API]: {
      types: ["entries/request", "entries/success", "entries/failure"],
      endpoint: `/entries/${values.entry_id}`,
      schema: [entrieSchema],
      method: "GET"
    }
  });
};

export { update, list, create, destroy };

import React from "react";
import { EuiBadge, EuiToolTip, EuiFieldText, EuiSpacer } from "@elastic/eui";
import "./style.scss";
const BadgeColumn = ({ entries, truncate = false }) => {
  return (
    <div className={"badges"}>
      {entries.map((e, i) => {
        if (truncate !== false && e.length > truncate) {
          return (
            <EuiToolTip key={i} content={e}>
              <EuiBadge>{e.slice(0, truncate)}...</EuiBadge>
            </EuiToolTip>
          );
        } else {
          return (
            <EuiBadge className="badge" key={i}>
              {e}
            </EuiBadge>
          );
        }
      })}
    </div>
  );
};

export default BadgeColumn;

import React from "react";
import Form from "./Form";
import { useFormik } from "formik";
import { update } from "ducks/distanceRules";
import { add } from "ducks/toasts";

import { connect } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
const EditCollection = ({ add, update, distanceRule }) => {
  const onClose = useRemoveQueryNavigation("editDistanceRule");
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: distanceRule,
    onSubmit: values => {
      update(values);
      add(
        "Update erfolgreich",
        "Benutzer wurde erfolgreich aktualisiert",
        "success",
        "user"
      );
    }
  });

  return values ? (
    <Form
      subtitle="Abstandsregel bearbeiten"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
    ></Form>
  ) : null;
};

export default connect(
  (state, { id }) => ({ distanceRule: state.distance_rules.items[id] }),
  { update, add }
)(EditCollection);

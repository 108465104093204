import { createSlice } from "@reduxjs/toolkit";
import uuid from "uuid/v4";
const slice = createSlice({
  name: "toasts",
  initialState: [],
  reducers: {
    add: {
      reducer(state, action) {
        return [...state, action.payload];
      },
      prepare(title, text, color = "info", iconType = "checkInCircleFilled") {
        return { payload: { id: uuid(), title, text, color, iconType } };
      }
    },

    remove(state, { payload }) {
      return state.filter(e => e.id !== payload.id); //TODO: CHECK
    }
  }
});

export default slice.reducer;
const { add, remove } = slice.actions;
export { add, remove };

import { useSelector } from "react-redux";
import { saveAs } from "file-saver";

const API_ROOT = process.env.REACT_APP_API_ENDPOINT;

export function useDownload() {
  const token = useSelector(state => state.auth.token);
  return async file => {
    const result = await fetch(`${API_ROOT}/files/${file.key}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const blob = await result.blob();
    saveAs(blob, file.name);
    return blob;
  };
}

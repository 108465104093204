import React from "react";
const Hover = ({ x, y, data }) => {
  return (
    <div
      style={{
        position: "absolute",
        left: x,
        top: y,
        pointerEvents: "none",
        zIndex: 9,
        fontSize: "12px",
        padding: "8px",
        backgroundColor: "rgba(0,0,0,0.8)",
        color: "#fff",
        minWidth: "160px",
        maxHeight: "240px",
        overflowY: "hidden"
      }}
    >
      {data.map(e => (
        <p>{e}</p>
      ))}
      {/* <hr style={{ margin: "7px 0px" }} />
      {entry.collection_ids.map(e => (
        <p>{collections[e].name}</p>
      ))} */}
    </div>
  );
};

export default Hover;

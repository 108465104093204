import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Page from "components/Page";

import { EuiButton } from "@elastic/eui";
import { useAddQueryNavigation } from "helpers/hooks/url";
import List from "./List";

const NewUserButton = () => {
  const query = useAddQueryNavigation();
  return (
    <EuiButton
      fill
      iconType="plusInCircle"
      onClick={() => query("createDistanceRule", true)}
    >
      Regel hinzufügen
    </EuiButton>
  );
};

const DistanceRules = () => {
  const match = useRouteMatch();
  return (
    <Page title="Abstandsregeln" actions={<NewUserButton />}>
      <Switch>
        <Route path={`${match.url}/List`}>
          <List />
        </Route>
        <Route path={`${match.url}`}>
          <List />
        </Route>
      </Switch>
    </Page>
  );
};

export default DistanceRules;

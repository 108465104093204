import { useMemo } from "react";
import { useSelector } from "react-redux";
import { GeoJsonLayer } from "deck.gl";
import { filterdEntriesMap } from "selectors/filter";
import { wrapGeoJson } from "helpers/geo/wrapGeo";

export default function EntriesLayer() {
  const entries = useSelector(filterdEntriesMap);
  const collections = useSelector(state => state.collections.items);
  const [mainEntriesLayer, data] = useMemo(() => {
    const data = wrapGeoJson(entries);
    return [
      new GeoJsonLayer({
        visible: true,
        data: data,
        pickable: true,
        id: "mainEntries",
        stroked: true,
        filled: true,
        extruded: false,
        lineWidthScale: 1,
        lineWidthMinPixels: 1,
        getRadius: 6,
        getFillColor: e => e.properties.color,
        pointRadiusMinPixels: 4
      }),
      data
    ];
  }, [entries, collections]);
  return [mainEntriesLayer, data];
}

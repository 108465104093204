import { createSlice } from "@reduxjs/toolkit";
import ducksHelper from "helpers/ducks";

const name = "neighbours";
const {
  defaultState,
  actions: { list }
} = ducksHelper(name);

const slice = createSlice({
  name: name,
  initialState: defaultState,
  reducers: {
    success: (state, action) => ({
      isLoading: false,
      update_at: new Date(),
      items: { ...state.items, ...action.payload.entities[name] }
    })
  }
});

export default slice.reducer;
export { list };

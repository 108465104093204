import React from "react";
import Users from "routes/Users";
import Files from "routes/Files";
import Collections from "routes/Collections";
import PropertyTemplates from "routes/PropertyTemplates";
import Filters from "routes/Filters";
import Entries from "routes/Entries";
import Import from "routes/Import";
import DistanceRules from "routes/DistanceRules";
import EntriesMap from "routes/EntriesMap";

import { Switch, Route } from "react-router-dom";
const Routes = () => (
  <Switch>
    <Route path="/Users">
      <Users />
    </Route>
    <Route path="/Files">
      <Files />
    </Route>
    <Route path="/PropertyTemplates">
      <PropertyTemplates />
    </Route>
    <Route path="/Collections">
      <Collections />
    </Route>
    <Route path="/Filters">
      <Filters />
    </Route>
    <Route path="/Entries">
      <Entries />
    </Route>
    <Route path="/import">
      <Import />
    </Route>
    <Route path="/distanceRules">
      <DistanceRules />
    </Route>
    <Route path="/entriesMap">
      <EntriesMap />
    </Route>
    <Route>
      <Entries />
    </Route>
  </Switch>
);

export default Routes;

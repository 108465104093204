import { useState, useCallback, useEffect } from "react";
import useDebouncedCallback from "./useDebouncedCallBack";
import { useSelector } from "react-redux";
const host = process.env.REACT_APP_API_ENDPOINT;

export async function neighboursLookUp(query, radius, token) {
  return await fetch(
    `${host}/neighbours/search?geo=${query}&radius=${radius}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }
  )
    // .then(repsonse => {
    //   if (!repsonse.ok) {
    //     throw Error(repsonse.statusText);
    //   }
    //   return repsonse;
    // })
    .then(e => e.json());
}

function useNeighboursLookUp(state) {
  const [query, setQuery] = useState(state);
  const [results, setResults] = useState([]);
  const token = useSelector(state => state.auth.token);
  const [debounceLookUp] = useDebouncedCallback(
    () => neighboursLookUp(query, token).then(e => setResults(e)),
    1000
  );
  useEffect(() => {
    debounceLookUp();
  }, [query]);
  return [results, setQuery];
}
export default useNeighboursLookUp;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import reducer from "ducks";
import thunk from "redux-thunk";
import api from "middleware/api";
const persistedState = JSON.parse(localStorage.getItem("auth")) || undefined;

const store = configureStore({
  reducer: reducer,
  middleware: [thunk, api],
  devTools: false && process.env.NODE_ENV !== "production",
  preloadedState: persistedState
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

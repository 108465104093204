import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ListFiles from "./List";
import Read from "./Read";

const Entries = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/List`}>
        <ListFiles />
      </Route>
      <Route path={`${match.url}/:id`}>
        <Read />
      </Route>
      <Route path={`${match.url}`}>
        <ListFiles />
      </Route>
    </Switch>
  );
};

export default Entries;

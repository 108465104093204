import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Page from "components/Page";

import { EuiButton } from "@elastic/eui";
import { useAddQueryNavigation } from "helpers/hooks/url";
import ListPropertyTemplates from "./List";
const NewCollectionButton = () => {
  const query = useAddQueryNavigation();

  return (
    <EuiButton
      fill
      iconType="plusInCircle"
      onClick={() => query("createPropertyTemplate", true)}
    >
      Datenformat hinzufügen
    </EuiButton>
  );
};

const PropertyTemplates = () => {
  const match = useRouteMatch();
  return (
    <Page title="Datenformate" actions={<NewCollectionButton />}>
      <Switch>
        <Route path={`${match.url}/List`}>
          <ListPropertyTemplates />
        </Route>
        <Route path={`${match.url}`}>
          <ListPropertyTemplates />
        </Route>
      </Switch>
    </Page>
  );
};

export default PropertyTemplates;

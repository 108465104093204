import React from "react";
import Form from "./Form";
import { useFormik } from "formik";
import { updateWithEntry } from "ducks/properties";
import { add } from "ducks/toasts";

import { connect } from "react-redux";
import { useRemoveQueryNavigation } from "helpers/hooks/url";
const EditProperty = ({ add, updateWithEntry, property }) => {
  const onClose = useRemoveQueryNavigation("editProperty");
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: property,
    onSubmit: values => {
      updateWithEntry(values);
      add(
        "Update erfolgreich",
        "Benutzer wurde erfolgreich aktualisiert",
        "success",
        "user"
      );
    }
  });

  return values ? (
    <Form
      subtitle="Einstellungen bearbeiten"
      values={values}
      handleChange={setFieldValue}
      handleSubmit={handleSubmit}
      handleClose={onClose}
    ></Form>
  ) : (
    false
  );
};

export default connect(
  (state, { id }) => ({ property: state.properties.items[id] }),
  { updateWithEntry, add }
)(EditProperty);

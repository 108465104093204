import React from "react";
import FormModal from "components/FormModal";
import { rgbToHex, hexToRgb } from "helpers/color";
import { useSelector } from "react-redux";

import {
  EuiColorPicker,
  EuiFormRow,
  EuiFieldText,
  EuiTextArea,
  EuiRange,
  EuiComboBox
} from "@elastic/eui";

const Form = ({ values, handleChange, ...props }) => {
  const collections = useSelector(state => state.collections.items);
  const collectionOptions = Object.values(collections)
    .map(e => ({
      id: e.id,
      label: e.name
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  return (
    <FormModal title="Abstandsregel" {...props}>
      <EuiFormRow label="Name">
        <EuiFieldText
          name="name"
          value={values.name}
          onChange={e => handleChange("name", e.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow label="Beschreibung">
        <EuiTextArea
          name="description"
          value={values.description}
          onChange={e => handleChange("description", e.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow label="Farbe">
        <EuiColorPicker
          name="color"
          color={rgbToHex(...values.color.slice(0, 3))}
          onChange={e =>
            handleChange("color", [...hexToRgb(e), values.color[3]])
          }
        />
      </EuiFormRow>

      <EuiFormRow label="Transparenz">
        <EuiRange
          min={0}
          max={100}
          step={1}
          tickInterval={25}
          value={values.color[3]}
          onChange={e =>
            handleChange("color", [
              ...values.color.slice(0, 3),
              parseInt(e.target.value, 10)
            ])
          }
          showInput
          showTicks
        />
      </EuiFormRow>

      <EuiFormRow label="Distanz">
        <EuiRange
          min={0}
          max={2000}
          step={10}
          tickInterval={500}
          value={values.distance}
          onChange={e => handleChange("distance", e.target.value)}
          //   showLabels
          showInput
          showTicks
        />
      </EuiFormRow>

      <EuiFormRow label="Ursprung">
        <EuiComboBox
          selectedOptions={values.source.map(e => ({
            label: collections[e].name,
            id: e
          }))}
          onChange={e => {
            handleChange(
              "source",
              e.map(x => x.id)
            );
          }}
          placeholder="Kollektionen auswählen"
          options={collectionOptions}
          isClearable={true}
        />
      </EuiFormRow>
      <EuiFormRow label="Ziel">
        <EuiComboBox
          selectedOptions={values.target.map(e => ({
            label: collections[e].name,
            id: e
          }))}
          onChange={e => {
            handleChange(
              "target",
              e.map(x => x.id)
            );
          }}
          placeholder="Kollektionen auswählen"
          options={collectionOptions}
          isClearable={true}
        />
      </EuiFormRow>
    </FormModal>
  );
};
export default Form;

import React, { useRef } from "react";
import "@elastic/eui/dist/eui_theme_light.css";
import Toasts from "features/Toasts";
import Header from "features/Header";

import NavDrawer from "features/NavDrawer";
import Auth from "features/Auth";
import Modals from "features/Modals";
import Routes from "routes";
import { useSelector } from "react-redux";
import isLoadingSelector from "selectors/loading";
import {
  EuiLoadingSpinner,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody
} from "@elastic/eui";
function App() {
  const navRef = useRef(null);
  const isLoading = useSelector(isLoadingSelector);
  return (
    <Auth>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Header navRef={navRef} />
          <NavDrawer ref={navRef} />
          <Routes />
          {/* <Toasts /> */}
          <Modals />
        </>
      )}
    </Auth>
  );
}

export default App;

const Loading = () => (
  <EuiPage style={{ height: "100vh" }}>
    <EuiPageBody>
      <EuiPageContent verticalPosition="center" horizontalPosition="center">
        <EuiPageContentBody style={{ width: "400px" }}>
          <div style={{ textAlign: "center" }}>
            <EuiLoadingSpinner size="xl" />
          </div>
        </EuiPageContentBody>
      </EuiPageContent>
    </EuiPageBody>
  </EuiPage>
);

import get from "helpers/utils/get";

export function formatEntry(entry, format) {
  return format.map(par =>
    par.children
      .map(e =>
        e.text === undefined ? get(entry, e.path, `<${e.path}>`) : e.text
      )
      .join("")
  );
}

export function getFormat(entry, collections) {
  let result = undefined;
  for (const collection_id of entry.collection_ids) {
    result = getFormatFromPath(collections[collection_id].path, collections);
    if (result) break;
  }

  return result;
}

function getFormatFromPath(path, collections) {
  if (collections[path[0]].format) {
    return collections[path[0]].format;
  } else if (path.length === 1) {
    return undefined;
  } else {
    return getFormatFromPath(path.slice(1), collections);
  }
}

export function useFormat(collections) {
  return entry => {
    const format = getFormat(entry, collections);

    return format === undefined
      ? "<Keine Formatierung festgelegt>"
      : formatEntry(entry, format);
  };
}

import { createSlice } from "@reduxjs/toolkit";
const host = process.env.REACT_APP_API_ENDPOINT;

const defaultState = {
  token: null,
  data: undefined,
  update_at: undefined,
  error: false
};

const slice = createSlice({
  name: "auth",
  initialState: defaultState,
  reducers: {
    failure: (state, { payload }) => payload,
    success: (state, { payload }) => payload,
    logout() {
      localStorage.clear();
      return defaultState;
    }
  }
});

function login(email, password) {
  return async function(dispatch) {
    const date = new Date();
    dispatch({
      type: "auth/login",
      payload: {
        email,
        password
      }
    });
    try {
      const response = await fetch(`${host}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email,
          password
        })
      });
      const json = await response.json();
      if (!response.ok) throw json.error;

      localStorage.setItem(
        "auth",
        JSON.stringify({
          auth: { token: json.token, data: json, date, error: false }
        })
      );
      return dispatch({
        type: "auth/success",
        payload: {
          token: json.token,
          data: {
            id: json.id,
            username: json.username,
            email: json.email,
            scope: json.scope
          },
          date,
          error: false
        }
      });
    } catch (e) {
      dispatch({
        type: "auth/failure",
        payload: { token: null, data: undefined, date, error: e }
      });
    }
  };
}
const { logout } = slice.actions;
export default slice.reducer;
export { login, logout };

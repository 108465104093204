import React, { useState, useMemo } from "react";
import NeighboursTable from "components/NeighboursTable";
//
import fullentries from "selectors/entries";
import { useEffect } from "react";
import { EuiSearchBar, EuiButtonIcon } from "@elastic/eui";
import useNeighbours from "helpers/hooks/data/useNeighbours";
import { useSelector, useDispatch } from "react-redux";
import { setPicked } from "ducks/ui/entriesMap";
import Options from "./Options";

const Pick = ({ data }) => {
  const dispatch = useDispatch();
  const entries = useSelector(fullentries);
  const entry = entries[data.id];
  const getNeighbours = useNeighbours(fullentries);
  const neighbours = useMemo(() => getNeighbours(entry), [
    entry,
    getNeighbours
  ]);

  const [query, setQuery] = useState({ queryText: "" });
  const [results, setResults] = useState([]);
  useEffect(() => {
    setResults(
      query.queryText === "" || query.error
        ? neighbours
        : EuiSearchBar.Query.execute(query.query, neighbours, {
            defaultFields: ["eintrag", "regel"]
          })
    );
  }, [query, neighbours]);

  const handleSearch = e => {
    setQuery(e);
  };

  return (
    <div>
      <div className="header">
        {entry.entryFormat.map((e, i) => (i === 0 ? <h3>{e}</h3> : <p>{e}</p>))}
        <div className="collections">
          {entry.collectionNames.map(e => (
            <span className="tag">{e}</span>
          ))}
        </div>
        <Options />
      </div>
      <div className="content">
        <NeighboursTable
          onClick={e => {
            const value = {
              source: "Entry",
              coordinates: e.geojson.coordinates,
              id: e.id
            };
            dispatch(setPicked(value));
          }}
          items={results}
          onSearch={handleSearch}
        ></NeighboursTable>
      </div>
    </div>
  );
};

export default Pick;

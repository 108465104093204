import React, { useMemo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  EuiBadge,
  EuiPopover,
  EuiContextMenu,
  EuiButtonEmpty
} from "@elastic/eui";
import { createEditor, Transforms } from "slate";

import {
  Slate,
  Editable,
  withReact,
  useFocused,
  useSelected
} from "slate-react";
import "./style.css";
import { EuiFlexGroup } from "@elastic/eui";
import { EuiFlexItem } from "@elastic/eui";
const FormatControl = ({ value, handleChange }) => {
  const editor = useMemo(() => withFields(withReact(createEditor())), []);
  const templates = useSelector(state => state.property_templates.items);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const renderElement = useCallback(props => {
    switch (props.element.type) {
      case "field":
        return <FieldElement {...props} />;
      default:
        return <DefaultElement {...props} />;
    }
  }, []);

  const insertTemplateButton = (
    <EuiButtonEmpty
      size="xs"
      iconType="plusInCircle"
      iconSide="left"
      flush="left"
      onClick={() => setPopoverOpen(true)}
    >
      Datenformat hinzufügen
    </EuiButtonEmpty>
  );

  const panels = [
    {
      id: 0,
      title: "Datenformate",
      items: [
        ...Object.values(templates).map(e => ({
          name: e.name,
          panel: e.id
        })),
        { name: "Kollisionen", panel: "collisions" }
      ]
    },
    {
      id: "collisions",
      title: "Kollisionen",
      items: [
        {
          name: "Total",
          onClick: () => {
            insert(editor, "Kollisionen(Total)", "collisions.total");
            setPopoverOpen(false);
          }
        }
      ]
    },
    ...Object.values(templates).map(e => ({
      id: e.id,
      title: e.name,
      items: e.fields.map(f => ({
        name: f.label,
        onClick: () => {
          insertField(editor, f, e);
          setPopoverOpen(false);
        }
      }))
    }))
  ];

  return (
    <EuiFlexGroup direction="column" gutterSize="xs">
      <EuiFlexItem>
        <EuiPopover
          id="contextMenu"
          button={insertTemplateButton}
          isOpen={isPopoverOpen}
          closePopover={() => setPopoverOpen(false)}
          panelPaddingSize="none"
          withTitle
          anchorPosition="downLeft"
        >
          <EuiContextMenu initialPanelId={0} panels={panels} />
        </EuiPopover>
      </EuiFlexItem>
      <EuiFlexItem>
        <Slate
          editor={editor}
          value={value}
          onChange={value => handleChange(value)}
        >
          <Editable className="formatField" renderElement={renderElement} />
        </Slate>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const DefaultElement = props => {
  return <p {...props.attributes}>{props.children}</p>;
};

const FieldElement = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <span {...attributes} contentEditable={false}>
      <EuiBadge color={selected && focused ? "primary" : "default"}>
        {element.label}
        {children}
      </EuiBadge>
    </span>
  );
};

export default FormatControl;

const insertField = (editor, field, template) => {
  const text = { text: "" };
  const node = {
    type: "field",
    id: field.id,
    field: field.label,
    template: template.name,
    tempalte_id: template.id,
    path: `data.${template.name}[0].${field.label}`,
    label: `${template.name} ${field.label}`,
    children: [text]
  };
  Transforms.insertNodes(editor, node);
};

const insert = (editor, label, path) => {
  const text = { text: "" };
  const node = {
    type: "field",
    id: path,
    path: path,
    label: label,
    children: [text]
  };
  Transforms.insertNodes(editor, node);
};

const withFields = editor => {
  const { isInline, isVoid } = editor;

  editor.isInline = element => {
    return element.type === "field" ? true : isInline(element);
  };

  editor.isVoid = element => {
    return element.type === "field" ? true : isVoid(element);
  };

  return editor;
};

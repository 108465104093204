import React from "react";
import { EuiOverlayMask, EuiConfirmModal } from "@elastic/eui";
const ConfirmModal = ({ title, close, confirm, text }) => (
  <EuiOverlayMask>
    <EuiConfirmModal
      title={title}
      onCancel={close}
      onConfirm={confirm}
      cancelButtonText="Abbrechen"
      confirmButtonText="Bestätigen"
      buttonColor="danger"
      defaultFocusedButton="confirm"
    >
      {text}
    </EuiConfirmModal>
  </EuiOverlayMask>
);

export default ConfirmModal

import { createSlice } from "@reduxjs/toolkit";
const slice = createSlice({
  name: "ui/filters",
  initialState: {},
  reducers: {
    reset: () => ({}),
    set: (state, { payload }) => {
      return payload;
    }
  }
});

export default slice.reducer;
const { set, reset } = slice.actions;
export { set, reset };

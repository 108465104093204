import React from "react";
import { EuiInMemoryTable, EuiSearchBar, EuiSpacer } from "@elastic/eui";
import { BadgeColumn } from "components/Columns";
import { useAddQueryNavigation } from "helpers/hooks/url";
import { useHistory } from "react-router-dom";

const NeighboursTable = ({ items = [], onSearch, onClick }) => {
  const addQuery = useAddQueryNavigation();
  const history = useHistory();

  const kollisionOptions = items
    .map(e => e.regel)
    .flat()
    .filter((e, i, a) => a.indexOf(e) === i)
    .sort((a, b) => a.localeCompare(b))
    .map(e => ({ name: e, value: e }));
  const kollektionOptions = items
    .map(e => e.kollektion)
    .flat()
    .filter((e, i, a) => a.indexOf(e) === i)
    .sort((a, b) => a.localeCompare(b))
    .map(e => ({ name: e, value: e }));
  const actions = [
    // {
    //   name: "Löschen",
    //   description: "Diesen Eintrag löschen",
    //   icon: "trash",
    //   color: "danger",
    //   type: "icon",
    //   onClick: e => {
    //     addQuery("deleteEntry", e.id);
    //   }
    // },
    {
      name: "Ansehen",
      description: "Diesen Eintrag ansehen",
      icon: "eye",
      type: "icon",

      onClick: e => {
        history.push(`/entries/${e.id}`);
      }
    },
    {
      name: "Edit",
      description: "Diesen Eintrag bearbeiten",
      icon: "pencil",
      type: "icon",

      onClick: e => {
        addQuery("editEntry", e.id);
      }
    }
  ];

  const columns = [
    {
      name: "Eintrag",
      field: "eintrag",
      sortable: true,
      render: e => (
        <div>
          {e.map(e => (
            <p>{e}</p>
          ))}
        </div>
      )
    },
    {
      render: e => <BadgeColumn entries={e} truncate={5} />,
      name: "Kollektion",
      field: "kollektion",
      sortable: true
    },
    {
      render: e => <BadgeColumn entries={e} truncate={5} />,
      name: "Regel",
      field: "regel",
      sortable: true
    },
    {
      sortable: true,
      name: "Abstand",
      field: "abstand",
      dataType: "number",
      render: distance =>
        new Intl.NumberFormat("de-DE", {
          style: "unit",
          unit: "meter",
          maximumFractionDigits: 0
        }).format(distance)
    },
    {
      name: "Aktionen",
      actions
    }
  ];
  const schema = {
    strict: true,
    fields: {
      abstand: {
        type: "number"
      },
      kollektion: {
        type: "string"
      },
      regel: {
        type: "string"
      },
      kollision: {
        type: "boolean"
      },
      eintrag: {
        type: "string"
      }
    }
  };
  const filters = [
    {
      type: "is",
      field: "kollision",
      name: "Kollision",
      negatedName: "Nachbar"
    },
    {
      type: "field_value_selection",
      field: "kollektion",
      name: "Kollektion",
      multiSelect: "or",
      options: kollektionOptions
    },
    {
      type: "field_value_selection",
      field: "regel",
      name: "Regel",
      multiSelect: "or",
      options: kollisionOptions
    }
  ];
  return (
    <>
      <EuiSearchBar
        box={{
          incremental: true,
          schema
        }}
        filters={
          kollisionOptions.length > 0
            ? filters
            : filters.filter(e => e.field !== "regel")
        }
        onChange={({ query, error, queryText }) => {
          onSearch({
            queryText: queryText,
            query: query,
            error: error
          });
        }}
      />
      <EuiSpacer size="s" />

      <EuiInMemoryTable
        rowProps={row => ({
          onClick: function(e) {
            if (e.nativeEvent.target.nodeName !== "BUTTON") {
              onClick(row);
            }
          }
        })}
        items={items}
        columns={
          kollisionOptions.length > 0
            ? columns
            : columns.filter(e => e.field !== "regel")
        }
        pagination={true}
        compressed
        tableLayout="auto"
        allowNeutralSort={true}
        hasActions
        sorting={{
          sort: {
            field: "abstand",
            direction: "asc"
          }
        }}
      ></EuiInMemoryTable>
    </>
  );
};

export default NeighboursTable;

import React from "react";
import FormModal from "components/FormModal";
import { rgbToHex, hexToRgb } from "helpers/color";
import { useSelector } from "react-redux";
import {
  EuiColorPicker,
  EuiFormRow,
  EuiSpacer,
  EuiFieldText,
  EuiTextArea,
  EuiRange,
  EuiFormFieldset,
  EuiSwitch,
  EuiSelect
} from "@elastic/eui";

import FormatField from "components/FormFields/FormatField";

const Form = ({ values, handleChange, ...props }) => {
  const collections = useSelector(state => state.collections.items);
  return (
    <FormModal title="Kollektion" {...props}>
      <EuiFormRow label="Name">
        <EuiFieldText
          name="name"
          value={values.name}
          onChange={e => handleChange("name", e.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow label="Beschreibung">
        <EuiTextArea
          name="description"
          value={values.description}
          onChange={e => handleChange("description", e.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow label="Elternknoten">
        <EuiSelect
          name="parent_id"
          options={[
            { value: null, text: "" },
            ...Object.values(collections).map(e => ({
              value: e.id,
              text: e.name
            }))
          ]}
          value={values.parent_id}
          onChange={e => handleChange("parent_id", e.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow label="Farbe">
        <EuiColorPicker
          name="color"
          color={rgbToHex(...values.color.slice(0, 3))}
          onChange={e =>
            handleChange("color", [...hexToRgb(e), values.color[3]])
          }
        />
      </EuiFormRow>

      <EuiFormRow label="Transparenz">
        <EuiRange
          min={0}
          max={100}
          step={1}
          tickInterval={25}
          value={values.color[3]}
          onChange={e =>
            handleChange("color", [
              ...values.color.slice(0, 3),
              parseInt(e.target.value, 10)
            ])
          }
          showInput
          showTicks
        />
      </EuiFormRow>

      <EuiFormRow label="Format">
        <EuiFormFieldset>
          <EuiSwitch
            label="Aktiv"
            onChange={() => {
              handleChange("withFormat", !values.withFormat);
            }}
            checked={values.withFormat}
          />
          {values.withFormat && (
            <>
              <EuiSpacer size="s" />
              <FormatField
                value={
                  values.format || [
                    {
                      type: "paragraph",
                      children: [{ text: "" }]
                    }
                  ]
                }
                handleChange={e => handleChange("format", e)}
              />
            </>
          )}
        </EuiFormFieldset>
      </EuiFormRow>
    </FormModal>
  );
};
export default Form;
